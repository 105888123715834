<template>
  <div>
    <div v-if="pageLoading && reservationData?.reservationCode" fluid>
      <CAlert
        v-if="reservationData.spendingObjection"
        class="mt-3"
        color="danger"
      >
        {{ $t("description.spending_objection") }}
      </CAlert>
      <CAlert
        class="mt-3"
        v-if="
          reservationData.paymentType == 'noneSecurePos' &&
          !isPay &&
          !reservationData.vendorReservationCode
        "
        color="warning"
      >
        <strong> {{ $t("caution") }}</strong>
        {{ $t("description.spending_desc") }}
      </CAlert>

      <RoleProvider slug="RESERVATION_UPDATE">
        <div
          v-if="
            reservationData.reservationType == 'fullCredit' &&
            reservationData.accountingStatus == 'pending' &&
            !isPartner
          "
          slot-scope="{ canItPass }"
        >
          <v-alert dense type="warning" class="ma-3" border="left" prominent>
            Rezervasyon <strong>FullCredit</strong> olduğundan tüm fatura ve
            yansıtmalar fatura onayından sonra kesilecektir.
            <div>
              <v-btn
                x-small
                dark
                color="green lighten-1"
                @click="approvedExtrasForVendorModal = true"
              >
                Faturalamayı Onayla
              </v-btn>

              <v-btn
                class="ml-3"
                x-small
                dark
                color="light"
                @click="addExtrasModal = true"
              >
                Yansıtma Ekle
              </v-btn>
            </div>
          </v-alert>
        </div></RoleProvider
      >
      <RoleProvider slug="RESERVATION_UPDATE">
        <div
          v-if="
            reservationData.reservationType == 'fullCredit' &&
            reservationData.accountingStatus == 'approved' &&
            !isPartner
          "
          slot-scope="{ canItPass }"
        >
          <v-alert dense type="info" class="ma-3" border="left" outlined>
            <strong>FullCredit</strong> rezervasyona ait fatura onayı verildi.
            Otomatik faturalama işlemi başladığında faturalandırılacktır.
          </v-alert>
        </div>
      </RoleProvider>

      <v-row>
        <v-col cols="12" lg="5" md="6" sm="12">
          <!-- ----REZERVASYON DETAY BAŞLANGIÇ ----REZERVATION DETAIL START ------->
          <v-card elevation="10" class="mb-5">
            <v-toolbar
              height="40"
              dense
              dark
              color="grey darken-1"
              elevation="0"
            >
              <v-toolbar-title style="font-size: 14px"
                ><strong> {{ $t("reservation") }} </strong> #{{
                  reservationData.reservationCode
                }}</v-toolbar-title
              >
              <v-spacer></v-spacer>
              {{ reservationData.searchPrice.car.brand }}
              {{ reservationData.searchPrice.car.model }}
            </v-toolbar>

            <v-row>
              <v-col
                cols="12"
                md="6"
                sm="12"
                class="align-items-center justify-start"
              >
                <v-img
                  height="70"
                  contain
                  :src="ENV_URL_CARS + reservationData.searchPrice.car.image"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
                sm="12"
                class="p-3 d-flex align-items-center"
              >
                <v-img
                  :src="
                    ENV_URL_VENDOR + reservationData.searchPrice.vendor.logo
                  "
                  max-height="70"
                  contain
                />
              </v-col>

              <v-col sm="12" md="12" class="ml-1">
                <v-row class="align-items-center justify-content-center pa-0">
                  <v-col cols="6" sm="6" md="3" class="pa-0">
                    <strong>
                      {{ $t("class") }}
                    </strong>
                    <p>
                      {{
                        $store.state.ceviri[
                          reservationData.searchPrice.car.class
                        ]
                      }}
                    </p>
                  </v-col>
                  <v-col cols="6" sm="6" md="3" class="p-0">
                    <strong>
                      {{ $t("gear") }}
                    </strong>
                    <p>
                      {{
                        $store.state.ceviri[
                          reservationData.searchPrice.car.transmission
                        ]
                      }}
                    </p>
                  </v-col>
                  <v-col cols="6" sm="6" md="3" class="p-0">
                    <strong>
                      {{ $t("fuel") }}
                    </strong>
                    <p>
                      {{
                        $store.state.ceviri[
                          reservationData.searchPrice.car.fuel
                        ]
                      }}
                    </p>
                  </v-col>
                  <v-col cols="6" sm="6" md="3" class="p-0">
                    <strong>
                      {{ $t("seat") }}
                    </strong>
                    <p>
                      {{ reservationData.searchPrice.car.seat }}
                    </p>
                  </v-col>

                  <v-col cols="6" sm="6" md="3" class="p-0">
                    <strong>
                      {{ $t("km_limit") }}
                    </strong>
                    <p>
                      {{
                        reservationData.searchPrice.rules.dailyRangeLimit ===
                          0 && !reservationData.isManuel
                          ? $t("unlimited")
                          : reservationData?.searchPrice?.rules
                              ?.totalRangeLimit + " KM"
                      }}
                    </p>
                  </v-col>
                  <v-col cols="6" sm="6" md="3" class="p-0">
                    <strong>
                      {{ $t("age") }}
                    </strong>
                    <p>
                      +
                      {{ reservationData.searchPrice.rules.driverAge }}
                    </p>
                  </v-col>
                  <v-col cols="6" sm="6" md="3" class="p-0">
                    <strong>
                      {{ $t("driving_license") }}
                    </strong>
                    <p>
                      {{ reservationData.searchPrice.rules.licenseYears }}
                      {{ $t("year") }}
                    </p>
                  </v-col>
                  <v-col cols="6" sm="6" md="3" class="p-0">
                    <strong>
                      {{ $t("fuel") }}
                    </strong>
                    <p>
                      {{
                        $store.state.ceviri[
                          reservationData.searchPrice.car.fuelPolicy
                        ]
                      }}
                    </p>
                  </v-col>
                  <v-col cols="6" sm="6" md="3" class="p-0">
                    <strong>
                      {{ $t("free_cancellation") }}
                    </strong>
                    <p class="font-weight-bold text-red" style="color: red">
                      {{
                        reservationData.searchPrice.vendor?.freeCancelInfo
                          ?.minHoursInfo
                          ? $t("free_cancel_text", {
                              minHoursInfo:
                                reservationData.searchPrice?.vendor
                                  ?.freeCancelInfo?.minHoursInfo,
                            })
                          : $t("available")
                      }}
                    </p>
                  </v-col>
                  <v-col cols="6" sm="6" md="3" class="p-0">
                    <strong>
                      {{ $t("early_return_1") }}
                    </strong>
                    <p class="font-weight-bold text-red" style="color: red">
                      {{
                        reservationData.searchPrice.vendor?.noEarlyReturn
                          ? $t("none")
                          : $t("available")
                      }}
                    </p>
                  </v-col>
                  <v-col cols="6" sm="6" md="3" class="p-0">
                    <strong>
                      {{ $t("noShowCancelled") }}
                    </strong>
                    <p class="font-weight-bold text-red" style="color: red">
                      {{
                        reservationData.searchPrice.vendor?.noNoShowReturn
                          ? $t("none")
                          : $t("available")
                      }}
                    </p>
                  </v-col>
                  <v-col cols="6" sm="6" md="3" class="p-0"> </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="m-0"></v-divider>
            <v-row>
              <v-col col="10">
                <v-row>
                  <v-col col="4" class="pa-0 ma-0 pl-1">
                    <h5 style="font-weight: 900">
                      <v-icon title="Alış Lokasyonu"> mdi-arrow-up-box </v-icon>
                      {{ reservationData.carDeliveryReturn.delivery.name }}
                    </h5>
                    <div class="d-flex align-items-center mt-3">
                      <font-awesome-icon
                        :icon="['fas', 'calendar-alt']"
                        size="lg"
                        class="mr-2"
                      />
                      <p class="h6 m-0">
                        {{
                          reservationData.carDeliveryReturn.delivery.datetime
                            | moment("Do MMMM YYYY, dddd HH:mm")
                        }}
                      </p>
                    </div>

                    <div class="d-flex align-items-center mt-3">
                      <font-awesome-icon
                        :icon="['fas', 'info-circle']"
                        size="lg"
                        class="mr-2"
                      />
                      <p class="h6 m-0">
                        {{
                          $store.state.ceviri[
                            reservationData.searchPrice.office.deliveryType
                          ]
                        }}
                      </p>
                    </div>
                    <p class="m-0 mt-1 small">
                      {{ reservationData.searchPrice.office.address }}
                    </p>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col col="6" class="pa-0 ma-0 pl-5">
                    <h5 style="font-weight: 900">
                      <v-icon title="İade Lokasyonu">
                        mdi-arrow-down-box
                      </v-icon>
                      {{ reservationData.carDeliveryReturn.return.name }}
                    </h5>
                    <div class="d-flex align-items-center mt-3">
                      <font-awesome-icon
                        :icon="['fas', 'calendar-alt']"
                        size="lg"
                        class="mr-2"
                      />
                      <p
                        class="h6 m-0"
                        v-if="reservationData.statusEarlyReturn !== `approved`"
                      >
                        {{
                          reservationData.carDeliveryReturn.return.datetime
                            | moment("Do MMMM YYYY, dddd HH:mm")
                        }}
                      </p>
                      <p
                        class="h6 m-0 text-decoration-line-through"
                        v-if="reservationData.statusEarlyReturn == `approved`"
                      >
                        {{
                          reservationData.earlyReturn?.oldCarDelivertReturnDate
                            | moment("Do MMMM YYYY, dddd HH:mm")
                        }}
                      </p>
                    </div>
                    <div
                      v-if="reservationData.statusEarlyReturn == `approved`"
                      class="d-flex align-items-center mt-3"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'calendar-alt']"
                        size="lg"
                        class="mr-2 red--text"
                      />
                      <p class="h6 m-0 red--text font-weight-bold">
                        {{
                          reservationData.carDeliveryReturn.return.datetime
                            | moment("Do MMMM YYYY, dddd HH:mm")
                        }}
                      </p>
                    </div>

                    <div class="d-flex align-items-center mt-3">
                      <font-awesome-icon
                        :icon="['fas', 'info-circle']"
                        size="lg"
                        class="mr-2"
                      />
                      <p class="h6 m-0">
                        {{
                          $store.state.ceviri[
                            reservationData.searchPrice.returnOffice
                              .deliveryType
                          ]
                        }}
                      </p>
                    </div>
                    <p class="m-0 mt-1 small">
                      {{ reservationData.searchPrice.returnOffice.address }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="m-0"></v-divider>

            <div
              v-if="reservationData.searchPrice.campaignData != null"
              class="pt-2 ml-3 mr-3"
            >
              <div>
                <strong>{{ $t("campaign") }} :</strong>
                {{
                  reservationData.searchPrice.campaignData.info.name.tr
                    ? reservationData.searchPrice.campaignData.info.name.tr
                    : reservationData.searchPrice.campaignData.info.name || ""
                }}
              </div>
              <div>
                <div class="pr-3">
                  <strong>{{ $t("discount") }} :</strong>
                  {{ reservationData.searchPrice.campaignData.discountType=='amount' ? 'TRY' :
                  reservationData.searchPrice.campaignData.discountType=='rate' ? '%' :  'Gün' }}
                  {{
                    reservationData.searchPrice.campaignData.discountRate ||
                    reservationData.searchPrice.campaignData.discountAmount
                  }}
                </div>
              </div>
            </div>
            <v-divider
              class="m-0"
              v-if="reservationData.searchPrice.campaignData != null"
            ></v-divider>
            <v-simple-table dense>
              <tbody>
                <tr>
                  <td>
                    <strong>{{ $t("daily_rental_fee") }}</strong>

                    <span
                      v-if="
                        reservationData?.prices?.dailyRentalPrice.TRY >
                        reservationData?.prices?.retailDailyRentalPrice?.TRY
                      "
                      ><br /><small>{{
                        $t("retailDailyRentalPrice")
                      }}</small></span
                    >
                  </td>
                  <td class="text-right">
                    <div class="font-weight-bold">
                      {{
                        func
                          .toMoneyFormat(
                            reservationData?.prices?.dailyRentalPrice[
                              reservationData.currency.code
                            ]
                          )
                          .toLocaleString(reservationData.lang, {
                            style: "currency",
                            currency: [reservationData.currency.code],
                          })
                      }}
                    </div>
                    <small v-if="!isPartner">
                      {{
                        func
                          .toMoneyFormat(
                            reservationData?.prices?.dailyRentalPrice.TRY
                          )
                          .toLocaleString(reservationData.lang, {
                            style: "currency",
                            currency: "TRY",
                          })
                      }}
                    </small>
                    <div
                      v-if="
                        reservationData?.prices?.dailyRentalPrice.TRY >
                        reservationData?.prices?.retailDailyRentalPrice.TRY
                      "
                    >
                      <small>
                        {{
                          func
                            .toMoneyFormat(
                              reservationData?.prices?.retailDailyRentalPrice[
                                reservationData.currency.code
                              ]
                            )
                            .toLocaleString(reservationData.lang, {
                              style: "currency",
                              currency: [reservationData.currency.code],
                            })
                        }}</small
                      >
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      {{ $t("rental_price") }}
                    </strong>
                    <span
                      v-if="
                        reservationData.prices.totalRentalPrice.TRY >
                        reservationData.prices.retailTotalRentalPrice.TRY
                      "
                      ><br /><small>{{
                        $t("retailTotalRentalPrice")
                      }}</small></span
                    >
                  </td>
                  <td class="text-right">
                    <span>
                      <div class="font-weight-bold">
                        {{
                          func
                            .toMoneyFormat(
                              reservationData?.prices?.totalRentalPrice[
                                reservationData.currency.code
                              ]
                            )
                            .toLocaleString(reservationData.lang, {
                              style: "currency",
                              currency: [reservationData.currency.code],
                            })
                        }}
                      </div>

                      <small v-if="!isPartner">
                        {{
                          func
                            .toMoneyFormat(
                              reservationData?.prices?.totalRentalPrice.TRY
                            )
                            .toLocaleString(reservationData.lang, {
                              style: "currency",
                              currency: "TRY",
                            })
                        }}
                      </small>
                      <div
                        v-if="
                          reservationData.prices.totalRentalPrice.TRY >
                          reservationData.prices.retailTotalRentalPrice.TRY
                        "
                      >
                        <small>
                          {{
                            func
                              .toMoneyFormat(
                                reservationData?.prices?.retailTotalRentalPrice[
                                  reservationData.currency.code
                                ]
                              )
                              .toLocaleString(reservationData.lang, {
                                style: "currency",
                                currency: [reservationData.currency.code],
                              })
                          }}</small
                        >
                      </div>
                    </span>
                  </td>
                </tr>
                <template
                  v-if="
                    reservationData.prices?.totalExtraPrice[
                      reservationData.currency.code
                    ] > 0
                  "
                >
                  <tr
                    v-for="(data, key) in reservationData.selectedExtras"
                    :key="key"
                  >
                    <td style="color: gray">
                      <strong
                        ><v-icon> mdi-chevron-right </v-icon>
                        {{ data.name }}</strong
                      >
                      <span v-if="data.isManually == true">
                        <small> ({{ data.extraInvoiceStatus }}) </small>
                      </span>
                    </td>

                    <td class="text-right" style="color: gray">
                      <span
                        v-if="
                          !(
                            updatedExtrasValue &&
                            data.isManually == true &&
                            data.extraInvoiceStatus == 'pending'
                          )
                        "
                      >
                        <div class="font-weight-bold">
                          <v-btn
                            v-if="
                              data.isManually == true &&
                              data.extraInvoiceStatus == 'pending' &&
                              !isPartner
                            "
                            x-small
                            color="warning"
                            class="ml-2"
                            icon
                            @click="
                              () => {
                                extraUpdateModal = true;
                                extraUpdateData = data;
                              }
                            "
                            ><v-icon>mdi-pencil-outline</v-icon></v-btn
                          >
                          {{
                            func
                              .toMoneyFormat(data.totalPrice)
                              [reservationData?.currency?.code].toLocaleString(
                                reservationData.lang,
                                {
                                  style: "currency",
                                  currency: [reservationData.currency.code],
                                }
                              )
                          }}
                        </div>
                        <span v-if="reservationData.currency.code != 'TRY'">
                          {{
                            func
                              .toMoneyFormat(
                                data.totalPrice[reservationData.currency.code]
                              )
                              .toLocaleString(reservationData.lang, {
                                style: "currency",
                                currency: [reservationData.currency.code],
                              })
                          }}
                        </span>
                      </span>
                      <v-text-field
                        v-if="
                          updatedExtrasValue &&
                          data.isManually == true &&
                          data.extraInvoiceStatus == 'pending'
                        "
                        variant="solo"
                        type="number"
                        min="1"
                        max="10000000"
                        v-model.number="
                          data.totalPrice[reservationData.currency.code]
                        "
                      />
                    </td>
                  </tr>
                </template>
                <tr
                  v-if="
                    reservationData.prices?.totalExtraPrice[
                      reservationData.currency.code
                    ] > 0 || reservationData.reservationType == 'fullCredit'
                  "
                >
                  <!-- Eğer Rez cari ve fullcredit ise buton gözüksün buton modalı açsın modaldan yansıtma eklenebilsin -->
                  <td>
                    <strong>
                      {{ $t("total_extra_fee") }}
                    </strong>
                  </td>

                  <td class="text-right">
                    <div class="font-weight-bold">
                      {{
                        func
                          .toMoneyFormat(
                            reservationData.prices?.totalExtraPrice[
                              reservationData.currency.code
                            ]
                          )
                          .toLocaleString(reservationData.lang, {
                            style: "currency",
                            currency: [reservationData.currency.code],
                          })
                      }}
                    </div>
                    <small v-if="!isPartner">
                      {{
                        func
                          .toMoneyFormat(
                            reservationData?.prices?.totalExtraPrice.TRY
                          )
                          .toLocaleString(reservationData.lang, {
                            style: "currency",
                            currency: "TRY",
                          })
                      }}
                    </small>
                  </td>
                </tr>
                <tr v-if="reservationData.prices.dropPrice.EUR > 0">
                  <td>
                    <strong
                      ><v-icon> mdi-chevron-right </v-icon>
                      {{ $t("drop_off_price") }}
                    </strong>
                  </td>
                  <td class="text-right">
                    <div class="font-weight-bold">
                      {{
                        func
                          .toMoneyFormat(
                            reservationData?.prices?.dropPrice[
                              reservationData.currency.code
                            ]
                          )
                          .toLocaleString(reservationData.lang, {
                            style: "currency",
                            currency: [reservationData.currency.code],
                          })
                      }}
                    </div>
                    <small v-if="!isPartner">
                      {{
                        func
                          .toMoneyFormat(reservationData?.prices?.dropPrice.TRY)
                          .toLocaleString(reservationData.lang, {
                            style: "currency",
                            currency: "TRY",
                          })
                      }}
                    </small>
                  </td>
                </tr>

                <tr v-if="reservationData.prices.officeServiceFee?.TRY > 0">
                  <td>
                    <strong
                      ><v-icon> mdi-chevron-right </v-icon>
                      {{ $t("office_service_fee") }}
                    </strong>
                  </td>
                  <td class="text-right">
                    <div class="font-weight-bold">
                      {{
                        func
                          .toMoneyFormat(
                            reservationData?.prices?.officeServiceFee?.[
                              reservationData.currency.code
                            ]
                          )
                          .toLocaleString(reservationData.lang, {
                            style: "currency",
                            currency: [reservationData.currency.code],
                          })
                      }}
                    </div>
                    <small v-if="!isPartner">
                      {{
                        func
                          .toMoneyFormat(
                            reservationData?.prices?.officeServiceFee?.TRY
                          )
                          .toLocaleString(reservationData.lang, {
                            style: "currency",
                            currency: "TRY",
                          })
                      }}
                    </small>
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>
                      {{ $t("amount_payable_on_delivery") }}
                    </strong>
                  </td>
                  <td class="text-right">
                    <span>
                      <div class="font-weight-bold">
                        {{
                          func
                            .toMoneyFormat(
                              reservationData?.prices?.payLater[
                                reservationData.currency.code
                              ]
                            )
                            .toLocaleString(reservationData.lang, {
                              style: "currency",
                              currency: [reservationData.currency.code],
                            })
                        }}
                      </div>
                      <small v-if="!isPartner">
                        {{
                          func
                            .toMoneyFormat(
                              reservationData?.prices?.payLater.TRY
                            )
                            .toLocaleString(reservationData.lang, {
                              style: "currency",
                              currency: "TRY",
                            })
                        }}
                      </small>
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>
                      {{ $t("total_fee") }}
                    </strong>
                    <small>
                      {{ $t("excluding_deposit") }}
                    </small>
                  </td>
                  <td class="text-right">
                    <span v-if="oldPrice">
                      <div class="font-weight-bold">
                        {{
                          func.toMoneyFormat(
                            (reservationData?.prices?.totalPrice[
                              reservationData.currency.code
                            ]).toLocaleString(reservationData.lang, {
                              style: "currency",
                              currency: [reservationData.currency.code],
                            })
                          )
                        }}
                      </div>
                    </span>
                    <span v-else>
                      <div class="font-weight-bold">
                        {{
                          func.toMoneyFormat(
                            (reservationData?.prices?.totalPrice[
                              reservationData.currency.code
                            ]).toLocaleString(reservationData.lang, {
                              style: "currency",
                              currency: [reservationData.currency.code],
                            })
                          )
                        }}
                      </div>
                      <small v-if="!isPartner">
                        {{
                          func
                            .toMoneyFormat(
                              reservationData?.prices?.totalPrice.TRY
                            )
                            .toLocaleString(reservationData.lang, {
                              style: "currency",
                              currency: "TRY",
                            })
                        }}
                      </small>
                    </span>
                  </td>
                </tr>

                <tr v-if="reservationData.reservationType != 'fullCredit'">
                  <td style="color: gray">
                    <strong>
                      {{ $t("deposit") }}
                    </strong>
                  </td>
                  <td style="color: gray" class="text-right">
                    <span v-if="oldPrice">
                      <div class="font-weight-bold">
                        {{
                          func
                            .toMoneyFormat(
                              reservationData?.prices?.provision[
                                reservationData.currency.code
                              ]
                            )
                            .toLocaleString(reservationData.lang, {
                              style: "currency",
                              currency: [reservationData.currency.code],
                            })
                        }}
                      </div>
                    </span>
                    <span v-else>
                      <div class="font-weight-bold">
                        {{
                          func
                            .toMoneyFormat(
                              reservationData?.prices?.provision[
                                reservationData.currency.code
                              ]
                            )
                            .toLocaleString(reservationData.lang, {
                              style: "currency",
                              currency: [reservationData.currency.code],
                            })
                        }}
                      </div>
                    </span>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
          <!-- ----REZERVASYON DETAY    BİTİŞ ----REZERVATION DETAIL END a ------->
        </v-col>
        <v-col cols="12" lg="7" md="6" sm="12" class="pa-0">
          <v-row>
            <v-col cols="12" lg="7" md="12" sm="12">
              <!-- ----SÜRÜCÜ BİLGİLERİ BAŞLANGIÇ ----DRİVER INFORMATION START ------->
              <v-card elevation="10">
                <v-toolbar
                  height="40"
                  dense
                  dark
                  color="grey darken-1"
                  elevation="0"
                  class="flex justify-between"
                >
                  <v-toolbar-title class="flex" style="font-size: 14px"
                    ><strong>
                      {{ $t("driver_info") }}
                    </strong>
                  </v-toolbar-title>
                  <RoleProvider slug="RESERVATION_UPDATE">
                    <template slot-scope="{ canItPass }">
                      <v-toolbar-title
                        style="display: flex; float: right"
                        class="flex justify-end"
                      >
                        <v-btn
                          v-if="canItPass"
                          color="green lighten-1"
                          @click="openDriverInfoUpdateForm()"
                          x-small
                          >Güncelle</v-btn
                        >
                      </v-toolbar-title></template
                    >
                  </RoleProvider>
                </v-toolbar>
                <div>
                  <v-row>
                    <v-col col="12" class="pa-0">
                      <v-simple-table dense>
                        <tbody>
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("name") }}
                                {{ $t("lastname") }}
                              </strong>
                            </td>
                            <td>
                              {{ reservationData.driverInfo.name }}
                              {{ reservationData.driverInfo.lastname }}
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>
                              <strong> {{ $t("phone") }} </strong>
                            </td>
                            <td>{{ phoneFormat }}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>
                                {{ $t("email") }}
                              </strong>
                            </td>
                            <td>{{ reservationData.driverInfo.email }}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>
                                {{ $t("identity") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $store.state.ceviri[
                                  reservationData.driverInfo.identity
                                    .certificateType
                                ]
                              }}
                              / {{ reservationData.driverInfo.identity.value }}
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>
                              <strong>
                                {{ $t("date_of_birth") }}
                              </strong>
                            </td>
                            <td>
                              {{ birthdayMoment }}
                              ({{ birthdayMoment && yearMoment }}
                              {{ $t("years_old") }}

                              )
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
              <CModal
                size="lg"
                :title="$t('driver_info')"
                :show.sync="driverInfoUpdate"
              >
                <v-form ref="driverInfoForm">
                  <v-row>
                    <v-col sm="6">
                      <v-text-field
                        outlined
                        dense
                        :label="$t('name')"
                        v-model="driverInfoUpdateForm.name"
                        :rules="[rules.required]"
                        @input="
                          driverInfoUpdateForm.name =
                            driverInfoUpdateForm.name.turkishToUpper()
                        "
                      />
                    </v-col>
                    <v-col sm="6">
                      <v-text-field
                        outlined
                        dense
                        :label="$t('lastname')"
                        :rules="[rules.required]"
                        v-model="driverInfoUpdateForm.lastname"
                        @input="
                          driverInfoUpdateForm.lastname =
                            driverInfoUpdateForm.lastname.turkishToUpper()
                        "
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="6">
                      <v-text-field
                        outlined
                        dense
                        :label="$t('phone')"
                        v-model="driverInfoUpdateForm.phone"
                        :rules="[rules.phone]"
                      />
                    </v-col>
                    <v-col sm="6">
                      <v-text-field
                        outlined
                        dense
                        :label="$t('email')"
                        v-model="driverInfoUpdateForm.email"
                        :rules="[rules.email]"
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    ><v-col sm="6">
                      <v-autocomplete
                        outlined
                        dense
                        :items="driverIdentityValue"
                        item-text="tr"
                        item-value="value"
                        :label="$t('identity_type')"
                        @input="driverIdentityValue"
                        v-model="driverInfoUpdateForm.identity.certificateType"
                      />
                    </v-col>
                    <v-col sm="6">
                      <v-text-field
                        outlined
                        dense
                        :label="
                          driverInfoUpdateForm.identity.certificateType == 'tc'
                            ? $t('tc_identity')
                            : $t('passport')
                        "
                        v-model="driverInfoUpdateForm.identity.value"
                        :rules="[rules.identity]"
                      />
                    </v-col>
                    <v-col cols="12">
                      <label
                        class="font-weight-bold"
                        v-html="$t('date_of_birth')"
                      />
                      <imask-input
                        class="form-control"
                        v-model="driverInfoUpdateForm.birthday"
                        :mask="Date"
                        :lazy="false"
                        radix="."
                        :unmask="false"
                        :placeholder="$t('date_of_birth')"
                      />
                    </v-col>
                  </v-row>
                </v-form>
                <template #footer>
                  <v-btn
                    @click="resDocumentUpdate('driverInfo')"
                    class="primary"
                    small
                    >Güncelle</v-btn
                  >
                </template>
              </CModal>
              <CModal
                size="lg"
                :title="$t('billing_information')"
                :show.sync="invoiceUpdate"
              >
                <v-row>
                  <v-col sm="12">
                    <v-autocomplete
                      outlined
                      dense
                      v-model="invoiceUpdateForm.invoiceType"
                      :items="invoiceTypeValue"
                      item-text="tr"
                      item-value="value"
                      :label="$t('contract')"
                      return-object
                      @input="updateInvoiceType"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-form ref="individual">
                  <v-row v-if="invoiceUpdateForm.invoiceType === 'individual'">
                    <v-col sm="12">
                      <v-text-field
                        outlined
                        dense
                        :rules="[rules.required]"
                        :label="$t('city')"
                        v-model="invoiceUpdateForm.individual.province"
                      />
                    </v-col>
                    <v-col sm="12">
                      <v-text-field
                        outlined
                        dense
                        :rules="[rules.required]"
                        :label="$t('post_code')"
                        v-model="invoiceUpdateForm.individual.postCode"
                      />
                    </v-col>
                    <v-col sm="12">
                      <v-text-field
                        outlined
                        dense
                        :rules="[rules.required]"
                        :label="$t('address')"
                        v-model="invoiceUpdateForm.individual.address"
                      />
                    </v-col>
                  </v-row>
                </v-form>
                <v-form ref="individualCompany">
                  <v-row
                    v-if="invoiceUpdateForm.invoiceType === 'individualCompany'"
                  >
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          outlined
                          dense
                          :rules="[rules.required]"
                          :label="$t('name')"
                          v-model="
                            invoiceUpdateForm.individualCompany.firstname
                          "
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          outlined
                          dense
                          :rules="[rules.required]"
                          :label="$t('lastname')"
                          v-model="invoiceUpdateForm.individualCompany.lastname"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          outlined
                          dense
                          :rules="[rules.required]"
                          :label="$t('tax_office')"
                          v-model="
                            invoiceUpdateForm.individualCompany.taxOffice
                          "
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          outlined
                          dense
                          min="10"
                          max="11"
                          v-mask="'###########'"
                          :rules="[rules.required]"
                          :label="$t('tax_number')"
                          v-model="
                            invoiceUpdateForm.individualCompany.taxNumber
                          "
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4">
                        <v-text-field
                          outlined
                          dense
                          :rules="[rules.required]"
                          :label="$t('city')"
                          v-model="invoiceUpdateForm.individualCompany.province"
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          outlined
                          dense
                          :rules="[rules.required]"
                          :label="$t('post_code')"
                          v-model="invoiceUpdateForm.individualCompany.postCode"
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          outlined
                          dense
                          :rules="[rules.required]"
                          :label="$t('address')"
                          v-model="invoiceUpdateForm.individualCompany.address"
                        />
                      </v-col>
                    </v-row>
                  </v-row>
                </v-form>
                <v-form ref="company">
                  <v-row v-if="invoiceUpdateForm.invoiceType === 'company'">
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          outlined
                          dense
                          :rules="[rules.required]"
                          :label="$t('title')"
                          v-model="invoiceUpdateForm.company.title"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          outlined
                          dense
                          :rules="[rules.required]"
                          :label="$t('tax_office')"
                          v-model="invoiceUpdateForm.company.taxOffice"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          outlined
                          dense
                          :rules="[rules.required]"
                          :label="$t('tax_number')"
                          v-model="invoiceUpdateForm.company.taxNumber"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          outlined
                          dense
                          :rules="[rules.required]"
                          :label="$t('city')"
                          v-model="invoiceUpdateForm.company.province"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          outlined
                          dense
                          :rules="[rules.required]"
                          :label="$t('post_code')"
                          v-model="invoiceUpdateForm.company.postCode"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          outlined
                          dense
                          :rules="[rules.required]"
                          :label="$t('address')"
                          v-model="invoiceUpdateForm.company.address"
                        />
                      </v-col>
                    </v-row>
                  </v-row>
                </v-form>
                <v-form ref="abroad">
                  <v-row v-if="invoiceUpdateForm.invoiceType === 'abroad'">
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          outlined
                          dense
                          :rules="[rules.required]"
                          :label="$t('title')"
                          v-model="invoiceUpdateForm.abroad.title"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          outlined
                          dense
                          :rules="[rules.required]"
                          :label="$t('country')"
                          v-model="invoiceUpdateForm.abroad.country"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          outlined
                          dense
                          :rules="[rules.required]"
                          :label="$t('city')"
                          v-model="invoiceUpdateForm.abroad.province"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          outlined
                          dense
                          :rules="[rules.required]"
                          :label="$t('address')"
                          v-model="invoiceUpdateForm.abroad.address"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          outlined
                          dense
                          :rules="[rules.required]"
                          :label="$t('tax_office')"
                          v-model="invoiceUpdateForm.abroad.taxOffice"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          outlined
                          dense
                          :rules="[rules.required]"
                          :label="$t('tax_number')"
                          v-model="invoiceUpdateForm.abroad.taxNumber"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          outlined
                          dense
                          :rules="[rules.required]"
                          :label="$t('post_code')"
                          v-model="invoiceUpdateForm.abroad.postCode"
                        />
                      </v-col>
                    </v-row>
                  </v-row>
                  <v-row>
                    <v-col v-if="invoiceDetail.invoiceTransferStatus">
                      <v-autocomplete
                        outlined
                        dense
                        v-model="invoiceERPForm.invoiceTransferStatus"
                        :items="invoiceTransferStatusValue"
                        item-text="tr"
                        item-val="value"
                        :label="$t('invoice_status')"
                        :rules="[rules.required]"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col v-if="invoiceDetail.invoiceNumber">
                      <v-text-field
                        outlined
                        dense
                        :label="$t('invoice_number')"
                        v-model="invoiceERPForm.invoiceNumber"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="invoiceDetail.invoiceDate">
                    <v-col cols="12">
                      <label
                        class="font-weight-bold"
                        v-html="$t('invoice_date')"
                      />
                      <imask-input
                        class="form-control"
                        v-model="invoiceERPForm.invoiceDate"
                        :mask="Date"
                        :lazy="false"
                        radix="."
                        :unmask="false"
                        :placeholder="$t('invoice_date')"
                      />
                    </v-col>
                  </v-row>
                </v-form>
                <template #footer>
                  <v-btn
                    @click="resDocumentUpdate('invoices')"
                    class="primary"
                    small
                    >Güncelle</v-btn
                  >
                </template>
              </CModal>
              <CModal
                size="sm"
                :title="$t('reservation_date_change')"
                :show.sync="reservationDateUpdateModal"
              >
                <v-row>
                  <v-col sm="12">
                    <VueCtkDateTimePicker
                      only-date
                      no-header
                      :minDate="
                        moment(
                          reservationData.carDeliveryReturn.return.datetime
                        )
                          .add(1, 'days')
                          .format('YYYY-MM-DD')
                      "
                      format="YYYY-MM-DD"
                      formatted="l"
                      label="Yeni Teslim Tarihini Seçiniz"
                      v-model="reservationDateUpdateForm.newDate"
                      :locale="locale"
                      auto-close
                    />
                  </v-col>
                  <v-col sm="12">
                    <v-text-field
                      outlined
                      dense
                      :rules="[rules.required]"
                      label="Yeni Api Günlük Fiyat"
                      v-model="reservationDateUpdateForm.newVendorPrice"
                    />
                  </v-col>

                  <v-col sm="12">
                    <v-text-field
                      outlined
                      dense
                      :rules="[rules.required]"
                      label="Yeni Müşteri Günlük Fiyat"
                      v-model="reservationDateUpdateForm.newRentalPrice"
                    />
                  </v-col>
                </v-row>
                <template #footer>
                  <v-btn
                    @click="reservationDateUpdateModal = false"
                    class="primary"
                    small
                    >İptal</v-btn
                  >
                  <v-btn
                    @click="
                      debitReservaitonDateChange(reservationDateUpdateForm)
                    "
                    color="green lighten-1"
                    small
                    >Güncelle</v-btn
                  >
                </template>
              </CModal>
              <!-- ----SÜRÜCÜ BİLGİLERİ BİTİŞ ----DRİVER INFORMATION END ------->

              <!-- ----FATURA BİLGİLERİ BAŞLANGIÇ ----BILLING INFORMATION START ------->
              <v-card elevation="10" class="mt-4">
                <v-toolbar
                  height="40"
                  dense
                  dark
                  color="grey darken-1"
                  elevation="0"
                  v-if="invoicesStatus"
                  class="flex justify-between"
                >
                  <v-toolbar-title class="flex" style="font-size: 14px"
                    ><strong>
                      {{ $t("billing_information") }}
                    </strong></v-toolbar-title
                  >
                  <RoleProvider slug="RESERVATION_UPDATE">
                    <template slot-scope="{ canItPass }">
                      <v-toolbar-title
                        style="display: flex; float: right"
                        class="flex justify-end"
                      >
                        <v-btn
                          v-if="canItPass"
                          color="green lighten-1"
                          v-model="invoiceUpdate"
                          @click="openInvoiceUpdateForm()"
                          x-small
                          >Güncelle</v-btn
                        >
                      </v-toolbar-title></template
                    ></RoleProvider
                  >
                </v-toolbar>
                <div v-if="invoicesStatus">
                  <v-row>
                    <v-col col="12" class="pa-0">
                      <v-simple-table dense>
                        <tbody>
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("type") }}
                              </strong>
                            </td>
                            <td>{{ invoiceDetail.invoiceType }}</td>
                          </tr>
                          <tr
                            v-if="
                              invoiceDetail.title &&
                              invoiceDetail.title !== 'undefined undefined'
                            "
                          >
                            <td>
                              <strong>
                                {{ $t("company_title") }}
                              </strong>
                            </td>
                            <td>
                              {{ invoiceDetail.title }}
                            </td>
                          </tr>
                          <tr v-if="partnerBrand">
                            <td>
                              <strong>
                                {{ $t("brand") }}
                              </strong>
                            </td>
                            <td>{{ partnerBrand }}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>
                                {{ $t("address") }}
                              </strong>
                            </td>
                            <td>
                              {{ invoiceDetail.address }}
                            </td>
                          </tr>
                          <tr v-if="invoiceDetail.taxOffice">
                            <td>
                              <strong>
                                {{ $t("tax_number") }}
                              </strong>
                            </td>
                            <td>
                              {{ invoiceDetail.taxOffice }} /
                              {{ invoiceDetail.taxNumber }}
                            </td>
                          </tr>
                          <tr v-if="invoiceDetail.invoiceNumber">
                            <td>
                              <strong>
                                {{ $t("status") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                invoiceDetail.invoiceTransferStatus == "ok"
                                  ? $t("invoiced")
                                  : invoiceDetail.invoiceTransferStatus ==
                                    "pending"
                                  ? $t("pending")
                                  : invoiceDetail.invoiceTransferStatus ==
                                    "error"
                                  ? $t("error_t")
                                  : ""
                              }}
                              <font-awesome-icon
                                v-if="invoiceDetail.invoiceERPMessage"
                                v-c-tooltip="{
                                  content: invoiceDetail.invoiceERPMessage,
                                  placement: 'top',
                                }"
                                :icon="['fas', 'info-circle']"
                                class="mr-2"
                              />
                            </td>
                          </tr>
                          <tr v-if="invoiceDetail.invoiceNumber">
                            <td>
                              <strong> {{ $t("invoice_number") }} </strong>
                            </td>
                            <td>
                              {{ invoiceDetail.invoiceNumber }}
                              <a
                                :href="
                                  apiURL +
                                  'admin/erp/get-invoice-pdf?guid=' +
                                  invoiceDetail.invoiceGUID +
                                  '&document_type=' +
                                  (invoiceDetail.invoiceNumber.includes('GGO')
                                    ? 'EARCHIVE'
                                    : 'EINVOICE')
                                "
                                v-if="invoiceDetail.invoiceGUID"
                                target="_blank"
                              >
                                <v-icon class="ml-2" color="green"
                                  >mdi-download-circle-outline</v-icon
                                >
                              </a>
                            </td>
                          </tr>
                          <tr v-if="invoiceDetail.invoiceDate">
                            <td>
                              <strong>
                                {{ $t("invoice_date") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                moment(invoiceDetail.invoiceDate).format(
                                  "DD-MM-YYYY"
                                )
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
              <!-- ----FATURA BİLGİLERİ BİTİŞ ----BILLING INFORMATION END ------->

              <!-- ----ÖDEME BİLGİLERİ  BAŞLANGIÇ ----PAYMENT INFORMATION START ------->
              <v-card
                elevation="10"
                class="mt-4"
                v-if="reservationData.paymentType != 'debit'"
              >
                <v-toolbar
                  height="40"
                  dense
                  dark
                  color="grey darken-1"
                  elevation="0"
                >
                  <v-toolbar-title style="font-size: 14px"
                    ><strong>
                      {{ $t("payment_info") }}
                    </strong></v-toolbar-title
                  >
                </v-toolbar>
                <div v-if="invoicesStatus">
                  <v-row>
                    <v-col col="12" class="pa-0">
                      <v-simple-table dense>
                        <tbody>
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("amount_paid") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                bankTransactions.TotalAmount
                                  ? func.toMoneyFormat(
                                      bankTransactions.TotalAmount
                                    ) + " ₺"
                                  : "0 ₺"
                              }}
                              <small
                                >(
                                {{
                                  bankTransactions.InstallmentCount == 1 ||
                                  !bankTransactions.InstallmentCount
                                    ? $t("advance")
                                    : $t("installment") +
                                      " /" +
                                      bankTransactions.InstallmentCount
                                }}
                                )</small
                              >
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <strong>
                                {{ $t("order_number") }}
                              </strong>
                            </td>
                            <td>
                              {{ reservationData && reservationData.orderId }}
                            </td>
                          </tr>
                          <tr v-if="bankTransactions.VposName">
                            <td>
                              <strong>
                                {{ $t("bank") }}
                              </strong>
                            </td>
                            <td>
                              {{ bankTransactions.VposName }}
                            </td>
                          </tr>
                          <tr v-if="bankTransactions.CardNumber">
                            <td>
                              <strong>
                                {{ $t("card_number") }}
                              </strong>
                            </td>
                            <td>
                              {{ bankTransactions.CardNumber }}
                            </td>
                          </tr>
                          <tr v-if="reservationData?.payments?.length">
                            <td>
                              <strong>
                                {{ $t("card_type") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                reservationData.payments[0].creditCardCountry ==
                                "nativeCard"
                                  ? $t("local")
                                  : reservationData?.payments[0]
                                      ?.creditCardCountry == "otherCard"
                                  ? $t("abroad")
                                  : ""
                              }}
                            </td>
                          </tr>
                          <tr v-if="reservationData?.payments?.length">
                            <td>
                              <strong>POS</strong>
                            </td>
                            <td>
                              {{ reservationData?.payments[0]?.posModule }}
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </div>
              </v-card>

              <!-- ----TEDARİKÇİYE NOT  BAŞLANGIÇ ------->
              <v-card
                elevation="10"
                class="mt-4"
                v-if="reservationData.description"
              >
                <v-toolbar
                  height="40"
                  dense
                  dark
                  color="grey darken-1"
                  elevation="0"
                >
                  <v-toolbar-title style="font-size: 14px"
                    ><strong>
                      {{ $t("description_to_supplier") }}
                    </strong></v-toolbar-title
                  >
                </v-toolbar>
                <div class="pa-3">
                  {{ reservationData.description }}
                </div>
              </v-card>
              <!-- ---- ÖDEME BİLGİLERİ BİTİŞ ----PAYMENT INFORMATION END ------->
            </v-col>

            <v-col cols="12" lg="5" md="12" sm="12">
              <!-- GENEL BİLGİLER BAŞLANGIÇ ---- GENERAL INFORMATION START ------->
              <v-card elevation="10" class="mb-5">
                <v-toolbar
                  height="40"
                  dense
                  dark
                  color="grey darken-1"
                  elevation="0"
                >
                  <v-toolbar-title style="font-size: 14px"
                    ><strong>{{ $t("general") }}</strong></v-toolbar-title
                  >
                </v-toolbar>
                <div>
                  <v-row>
                    <v-col col="12" class="pa-0">
                      <v-simple-table dense>
                        <tbody>
                          <tr>
                            <td style="width: 150px">
                              <strong>
                                {{ $t("status") }}
                              </strong>
                            </td>
                            <td>
                              {{ $store.state.ceviri[reservationData.status] }}
                            </td>
                          </tr>
                          <tr v-if="reservationData.statusEarlyReturn != null">
                            <td style="width: 150px">
                              <strong>
                                {{ $t("status_early_return") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $store.state.ceviri[
                                  "earlyReturn_" +
                                    reservationData.statusEarlyReturn
                                ]
                              }}
                            </td>
                          </tr>

                          <!-- <tr v-if="reservationData?.refund?.status==true">test
                            <td >  
                              <strong>
                                {{ $t("return_status") }}
                              </strong>
                            </td>
                            <td>{{ reservationData.refund.place}}</td>
                          </tr> -->

                          <tr>
                            <td>
                              <strong>
                                {{ $t("reservation_number") }}
                              </strong>
                            </td>
                            <td>{{ reservationData.reservationCode }}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>
                                {{ $t("vendor_reservation_number") }}
                              </strong>
                            </td>
                            <td>{{ reservationData.vendorReservationCode }}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>
                                {{ $t("day") }}
                              </strong>
                            </td>
                            <td>{{ reservationData.searchPrice.totalDays }}</td>
                          </tr>

                          <tr
                            v-if="
                              reservationData.status == `confirmedEarlyReturn`
                            "
                          >
                            <td>
                              <strong class="text-decoration-underline">
                                {{ "İade Gün" }}
                              </strong>
                            </td>
                            <td class="text-decoration-underline">
                              <!-- {{ EarlyReturnCardInfo.DiffTime }} -->
                              {{ earlyReturnCalcDays }}
                            </td>
                          </tr>
                          <tr v-if="reservationData.searchPrice.createdAt">
                            <td>
                              <strong>
                                {{ $t("search_date") }}
                              </strong>
                            </td>
                            <td style="font-size: 11px">
                              {{
                                moment(reservationData.searchPrice.createdAt)
                                  .utc()
                                  .local()
                                  .format("DD-MM-YYYY HH:mm")
                              }}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <strong>
                                {{ $t("process_date") }}
                              </strong>
                            </td>
                            <td style="font-size: 11px">
                              {{
                                moment(reservationData.reservationDateTime)
                                  .utc()
                                  .local()
                                  .format("DD-MM-YYYY HH:mm")
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>
                                {{ $t("trader") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                reservationData?.modifications[0].userType ==
                                  "agent" ||
                                reservationData?.modifications[0].userType ==
                                  "admin"
                                  ? $store.state.ceviri[
                                      reservationData?.modifications[0]
                                        ?.departmanRole
                                    ]
                                  : $store.state.ceviri[
                                      reservationData?.modifications[0]
                                        ?.userType
                                    ]
                              }}
                              <small
                                class="font-weight-bold d-block"
                                v-if="reservationData.partnerDetail"
                                >{{ reservationData?.partnerDetail?.title }}
                              </small>
                              <small class="font-weight-bold d-block"
                                >{{
                                  reservationData?.modifications[0]?.fullname
                                }}
                              </small>
                            </td>
                          </tr>
                          <tr v-if="reservationData.paymentType">
                            <td>
                              <strong>
                                {{ $t("payment_type") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $store.state.ceviri[reservationData.paymentType]
                              }}
                            </td>
                          </tr>
                          <tr v-if="reservationData.reservationType">
                            <td>
                              <strong>
                                {{ $t("reservation_type") }}
                              </strong>
                            </td>
                            <td>
                              {{ $t(reservationData.reservationType) }}
                            </td>
                          </tr>
                          <tr
                            v-if="
                              reservationData?.currency?.forexSelling > 1 &&
                              !isPartner
                            "
                          >
                            <td>
                              <strong> Kur </strong>
                            </td>
                            <td>
                              {{ reservationData?.currency?.forexSelling }}
                            </td>
                          </tr>

                          <tr v-if="reservationData?.utmSource?.length">
                            <td>
                              <strong>
                                {{ $t("Kaynak") }}
                              </strong>
                            </td>
                            <td>
                              {{ reservationData.utmSource }}
                            </td>
                          </tr>
                          <tr
                            v-if="
                              reservationData?.metropolCard?.length && isPay
                            "
                          >
                            <td>
                              <strong> Metropol Kart </strong>
                            </td>
                            <td>
                              {{ reservationData?.metropolCard }}
                            </td>
                          </tr>
                          <tr v-if="reservationData.status === 'pending'">
                            <td>
                              <strong> Son Aramalar İzni </strong>
                            </td>
                            <td>
                              {{
                                reservationData?.parameters
                                  ?.lastSearchPermission === true
                                  ? "VAR"
                                  : reservationData?.parameters
                                      .lastSearchPermission === false
                                  ? "YOK"
                                  : "BOŞ"
                              }}
                            </td>
                          </tr>
                          <tr
                            v-if="
                              reservationData.driverInfo.flightNo &&
                              reservationData.driverInfo.flightNo != ''
                            "
                          >
                            <td>
                              <strong>
                                {{ $t("flight_number") }}
                              </strong>
                            </td>
                            <td>
                              {{ reservationData.driverInfo.flightNo }}
                            </td>
                          </tr>
                          <!-- <tr v-if="reservationData.status == 'cancelled'">
                            <td>
                              <strong>
                                {{ $t("cancel_date") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                moment(reservationCancellationDate)
                                  .local()
                                  .format("DD-MM-YYYY HH:mm")
                              }}
                            </td>
                          </tr>

                          <tr v-if="reservationData.status == 'cancelled'">
                            <td>
                              <strong>
                                {{ $t("cancel_author") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                reservationCancelData?.departmanRole
                                  ? $store.state.ceviri[
                                      reservationCancelData?.departmanRole
                                    ]
                                  : reservationCancelData?.place
                              }}
                              <small class="font-weight-bold d-block"
                                >{{ reservationCancelData?.author }}
                              </small>
                            </td>
                          </tr>

                          <tr v-if="reservationData?.status == 'cancelled'">
                            <td>
                              <strong>
                                {{ $t("reason_for_cancellation") }}
                              </strong>
                            </td>
                            <td>
                              {{ reservationCancelData?.message }}
                            </td>
                          </tr> -->
                        </tbody>
                      </v-simple-table>

                      <v-col
                        v-if="
                          reservationData.status != 'paymentError' &&
                          reservationData.status != 'pending'
                        "
                      >
                        <v-btn
                          class="mb-1 down-btn"
                          block
                          outlined
                          small
                          color="primary font-weight-bold"
                          v-if="
                            reservationData.status != 'pending' &&
                            reservationData.status != 'cancelled' &&
                            reservationData.status != 'completed'
                          "
                          @click="reservationEmailSend(reservationData._id)"
                          style="white-space: normal; word-wrap: break-word"
                        >
                          {{ $t("send_reservation_email") }}
                        </v-btn>
                        <v-btn
                          class="mb-1 down-btn"
                          block
                          small
                          color="primary font-weight-bold"
                          v-if="
                            reservationData.status == 'pending' ||
                            reservationData.status == 'paymentError'
                          "
                          @click="beginIVR(reservationData.reservationCode)"
                          style="white-space: normal; word-wrap: break-word"
                        >
                          {{ $t("forward_call_ivr") }}
                        </v-btn>

                        <v-btn
                          class="mb-1 down-btn"
                          block
                          outlined
                          small
                          color="primary  font-weight-bold"
                          v-if="reservationData.status != 'pending'"
                          @click="
                            reservationPdfDownload(
                              reservationData.driverInfo.email,
                              reservationData.reservationCode
                            )
                          "
                          style="white-space: normal; word-wrap: break-word"
                        >
                          {{ $t("download_reservation_document") }}
                        </v-btn>
                        <RoleProvider slug="RESERVATION_EARLY_RETURN_BEGIN">
                          <div slot-scope="{ canItPass }" v-if="!isPartner">
                            <v-btn
                              class="mb-1"
                              block
                              small
                              color="warning text-white font-weight-bold"
                              @click="formEarlyReturnRegistrationModal = true"
                              v-if="
                                reservationData.statusEarlyReturn == null ||
                                reservationData.statusEarlyReturn == 'null' ||
                                reservationData.statusEarlyReturn ==
                                  'approved' ||
                                (reservationData.statusEarlyReturn ==
                                  'rejected' &&
                                  canItPass)
                              "
                            >
                              {{ $t("early_return_registration") }}
                            </v-btn>
                          </div>
                        </RoleProvider>

                        <!-- ////////////////////////// -->
                        <!--

                       -->
                        <RoleProvider
                          slug="RESERVATION_REFUND_PAYMENT"
                          v-if="$store.state.GetServerData.userType === 'admin'"
                        >
                          <div slot-scope="{ canItPass }">
                            <v-btn
                              v-if="reservationData.status != 'approved'"
                              class="mb-1"
                              block
                              small
                              color="secondary text-white font-weight-bold"
                              @click="noShowRefundPaymentModel = true"
                            >
                              {{ $t("noshow_refund_payment") }}
                            </v-btn>
                          </div>
                        </RoleProvider>
                        <!-- ///////////////////////////////// -->

                        <RoleProvider slug="RESERVATION_COMMENT_CREATE">
                          <div slot-scope="{ canItPass }">
                            <v-btn
                              class="mb-1"
                              block
                              small
                              color="warning text-white font-weight-bold"
                              v-if="
                                reservationData.status == 'completed' &&
                                canItPass
                              "
                              @click="
                                surveySend(reservationData.reservationCode)
                              "
                            >
                              {{ $t("send_survey") }}
                            </v-btn>
                          </div>
                        </RoleProvider>

                        <RoleProvider slug="RESERVATION_UPDATE">
                          <div slot-scope="{ canItPass }">
                            <v-btn
                              class="mb-1"
                              block
                              small
                              color="warning text-white font-weight-bold"
                              v-if="
                                reservationData.consensus.status !=
                                  'completed' &&
                                reservationData.paymentType == 'debit' &&
                                reservationData.statusEarlyReturn !=
                                  'approved' &&
                                reservationData.status != 'noShowCancelled' &&
                                canItPass &&
                                !isPartner
                              "
                              @click="reservationDateUpdateModal = true"
                            >
                              {{ $t("res_date_change") }}
                            </v-btn>
                          </div>
                        </RoleProvider>
                        <RoleProvider slug="RESERVATION_DELETE">
                          <div slot-scope="{ canItPass }">
                            <v-btn
                              class="mb-1"
                              small
                              block
                              color="error font-weight-bold"
                              v-if="
                                reservationData.status !== 'cancelled' &&
                                reservationData.status !==
                                  'earlyReturnRequest' &&
                                reservationData.status !==
                                  'earlyReturnFinancialConfirmation' &&
                                reservationData.status !== 'pending' &&
                                reservationData.consensus.status !=
                                  'completed' &&
                                canItPass
                              "
                              @click="cacelBtnClick('all')"
                            >
                              {{ $t("cancel_btn") }}
                            </v-btn>
                          </div>
                        </RoleProvider>
                        <RoleProvider slug="RESERVATION_DELETE">
                          <div slot-scope="{ canItPass }">
                            <v-btn
                              class="mb-1"
                              small
                              block
                              color="light"
                              @click="reservationSetting.modalShow = true"
                              v-if="
                                canItPass &&
                                reservationData.status != 'cancelled' &&
                                !isPartner
                              "
                            >
                              {{ $t("change") }}
                            </v-btn>
                          </div>
                        </RoleProvider>
                        <RoleProvider slug="RESERVATION_UPDATE">
                          <div slot-scope="{ canItPass }">
                            <v-btn
                              class="mb-1"
                              small
                              block
                              color="light"
                              @click="addExtrasModal = true"
                              v-if="
                                canItPass &&
                                reservationData.reservationType == 'fullCredit'
                              "
                            >
                              Yansıtma Ekle
                            </v-btn>
                          </div>
                        </RoleProvider>
                      </v-col>
                      <v-col>
                        <RoleProvider slug="RESERVATION_COMMISION_VIEW">
                          <div slot-scope="{ canItPass }">
                            <v-btn
                              class="mb-1 text-capitalize"
                              small
                              block
                              color="light"
                              @click="getSearchQueryApiLog()"
                              v-if="canItPass"
                            >
                              Teklif API Logları
                            </v-btn>
                          </div>
                        </RoleProvider>
                        <RoleProvider slug="RESERVATION_COMMISION_VIEW">
                          <div slot-scope="{ canItPass }">
                            <v-btn
                              class="mb-1 text-capitalize"
                              small
                              block
                              color="light"
                              @click="getReservationApiLog()"
                              v-if="canItPass"
                            >
                              Rezervasyon API Logları
                            </v-btn>
                          </div>
                        </RoleProvider>
                        <RoleProvider
                          slug="RESERVATION_CANCEL_REFLECTION"
                          v-if="
                            reservationData.status === 'cancelled' &&
                            reservationData.refund.status === false
                          "
                        >
                          <div slot-scope="{ canItPass }">
                            <v-btn
                              class="mb-1 text-capitalize text-white"
                              small
                              block
                              color="red"
                              @click="addResCancelReflectionModal()"
                              v-if="canItPass"
                            >
                              Rezervasyon İptal Bilgileri Yansıt
                            </v-btn>
                          </div>
                        </RoleProvider>
                      </v-col>
                      <v-col>
                        <v-btn
                          class="mb-1"
                          block
                          small
                          color=" font-weight-bold"
                          v-if="
                            reservationData.paymentType == 'noneSecurePos' &&
                            !isPay
                          "
                          @click="paymentModalShow = !paymentModalShow"
                        >
                          {{ $t("pay_with_virtual_pos") }}
                        </v-btn>
                      </v-col>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- GENEL BİLGİLER BİTİŞ ---- GENERAL INFORMATION END ------->

      <v-row>
        <v-col md="12" sm="12" cols="12">
          <!-- İPTAL BİLGİLER KART BAŞLANGIÇ ---- CANCEL INFORMATIONCARD  START ------->
          <v-card elevation="10" class="mb-5" v-if="cancelRezCarInfo?.CardShow">
            <v-toolbar
              height="40"
              dense
              dark
              class="grey darken-1"
              elevation="0"
            >
              <v-toolbar-title style="font-size: 14px"
                ><strong>
                  {{ cancelRezCarInfo.title }}
                </strong></v-toolbar-title
              >
            </v-toolbar>
            <v-card-text class="px-4 pt-2 pb-3">
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <strong>İŞLEM TİPİ:</strong>
                  {{ cancelRezCarInfo.type }}
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <strong>İADE EDİLEN TUTAR:</strong>
                  {{ cancelRezCarInfo.refundPrice }}
                  <!-- <span v-if="payments">
                (*Tedarikçi Kurallı iade)
               </span> Enesss -->
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <strong>İPTAL EDEN KULLANICI:</strong>
                  {{ cancelRezCarInfo.personInfo }}
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  v-if="cancelRezCarInfo.place !== `Agent`"
                >
                  <strong>İPTAL EDEN :</strong>
                  {{ cancelRezCarInfo.place }}
                </v-col>
                <v-col cols="12" sm="6" md="4" v-else>
                  <strong>İPTAL EDEN DEPARTMANI:</strong>
                  {{ cancelRezCarInfo.departmanRole }}
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <strong>İPTAL TARİHİ:</strong>
                  {{
                    moment(cancelRezCarInfo.date)
                      .utc()
                      .local()
                      .format("DD-MM-YYYY HH:mm")
                  }}
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <strong>İPTAL MESAJI:</strong>
                  {{ cancelRezCarInfo.message }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- İPTAL BİLGİLER KART BİTİŞ ---- CANCEL INFORMATIONCARD  END ------->

          <!-- NOSHOW KART BAŞLANGIÇ ---- NOSHOW CARD  START ------->
          <v-card
            elevation="10"
            class="mb-5"
            v-if="reservationData?.noShowRefundPayments?.status"
          >
            <v-toolbar
              height="40"
              dense
              dark
              class="grey darken-1"
              elevation="0"
            >
              <v-toolbar-title style="font-size: 14px"
                ><strong> NOSHOW İPTAL BİLGİLERİ </strong></v-toolbar-title
              >
            </v-toolbar>
            <v-card-text class="px-4 pt-2 pb-3">
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <strong>İptal Tarihi :</strong>
                  {{
                    moment(reservationData?.refund?.date)
                      .local()
                      .format("DD-MM-YYYY HH:mm")
                  }}
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <strong>{{
                    reservationData?.reservationType !== `debit`
                      ? "İade Edilen Tutar:"
                      : "İadesi Talep Edilen Tutar:"
                  }}</strong>
                  {{ reservationData?.noShowRefundPayments?.price }}
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <strong>İşlemi Gerçekleştiren:</strong>
                  {{ noShowAudits.personInfo }}{{ "/" }}
                  {{ noShowAudits.departmanRole }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- NOSHOW KART BİTİŞ ---- NOSHOW CARD  END ------->

          <!-- ERKEN İADE KART BAŞLANGIÇ ---- EARLYRETURN  CARD  START ------->
          <v-card
            elevation="10"
            class="mb-5"
            v-if="
              reservationData?.statusEarlyReturn &&
              ['pending', 'approved', 'rejected'].includes(
                reservationData.statusEarlyReturn
              )
            "
          >
            <v-toolbar
              height="40"
              dense
              dark
              class="grey darken-1"
              elevation="0"
            >
              <v-toolbar-title style="font-size: 14px"
                ><strong>
                  {{
                    reservationData?.statusEarlyReturn == `pending`
                      ? "ERKEN İADE İSTEĞİ ONAY TALEBİ BİLGİLERİ"
                      : reservationData?.statusEarlyReturn == `approved`
                      ? "ONAYLANAN ERKEN İADE BİLGİLERİ"
                      : "ERKEN İADE TALEBİ RED BİLGİLERİ"
                  }}
                </strong></v-toolbar-title
              >
            </v-toolbar>
            <v-card-text class="px-4 pt-2 pb-3">
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <strong>İade Tarihi :</strong>
                  {{
                    moment(reservationData?.earlyReturn?.refundDate)
                      .local()
                      .format("D MMMM YYYY")
                  }}
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <strong>İade Ücreti:</strong>
                  {{ reservationData?.earlyReturn?.calculatedRefundPrice }}
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <strong>İade Mesajı:</strong>
                  {{ reservationData?.refund?.message }}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <strong>İşlemi Gerçekleştiren:</strong>
                  {{ earlyReturnAudits.personInfo }}
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <strong>Departman:</strong>
                  {{ earlyReturnAudits.departmanRole }}
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <strong>İşlem Tarihi:</strong>
                  {{
                    moment(earlyReturnAudits.date).local().format("D MMMM YYYY")
                  }}
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <RoleProvider slug="RESERVATION_EARLY_RETURN_SUCCESS_REJECT">
                <div slot-scope="{ canItPass }">
                  <v-btn
                    class="mb-1 ml-3"
                    small
                    v-if="
                      reservationData.statusEarlyReturn == 'pending' &&
                      canItPass
                    "
                    color="success text-white font-weight-bold"
                    @click="confirmEarlyReturn()"
                  >
                    {{ $t("early_return_confirmation") }}
                  </v-btn>
                </div>
              </RoleProvider>

              <RoleProvider slug="RESERVATION_EARLY_RETURN_BEGIN">
                <div slot-scope="{ canItPass }">
                  <v-btn
                    class="mb-1 ml-3"
                    v-if="
                      reservationData.statusEarlyReturn == 'pending' &&
                      canItPass
                    "
                    small
                    color="error font-weight-bold"
                    @click="rejectEarlyReturn()"
                  >
                    {{ $t("early_return_rejection") }}
                  </v-btn>
                </div>
              </RoleProvider>
            </v-card-actions>
          </v-card>

          <!-- ERKEN İADE KART BİTİŞ ---- EARLYRETURN CARD  END ------->

          <!-- İADE ÖDEMESİ KART BAŞLANGIÇ ---- İADE ÖDEMESİ CARD  START ------->
          <!-- <v-card elevation="10" class="mb-5" v-if="refundPayCardInfo.CardShow">
            <v-toolbar height="40" dense dark class="white--text" style="background-color: red;" elevation="0">
              <v-toolbar-title style="font-size: 14px "><strong>
                  {{ refundPayCardInfo.title }}
                </strong></v-toolbar-title>
            </v-toolbar>

            <CCardText class="mb-1">
              <v-row>
                <v-col class="col-3">
                  {{ "İADE TİPİ :" }}
                </v-col>
                <v-col class="col-3  font-weight-bold">
                  {{ refundPayCardInfo.type }}
                </v-col>
                <v-divider vertical></v-divider>
                <v-col class="col-3">
                  {{ "İADE EDEN KULLANICI TİPİ :" }}
                </v-col>
                <v-col class="col-3  font-weight-bold">
                  {{ refundPayCardInfo.person }}
                </v-col>
              </v-row>

              <v-row>
                <v-col class="col-3">
                  {{ "İADE EDEN KULLANICI :" }}
                </v-col>
                <v-col class="col-3  font-weight-bold">
                  {{ refundPayCardInfo.personInfo }}
                </v-col>
                <v-divider vertical></v-divider>
                <v-col class="col-3">
                  {{ "İADE EDEN DEPARTMANI : " }}
                </v-col>
                <v-col class="col-3 t font-weight-bold">
                  {{ refundPayCardInfo.departmanRole }}
                </v-col>
              </v-row>

              <v-row>
                <v-col class="col-3">
                  {{ "İADE TARİHİ" }}
                </v-col>
                <v-col class="col-3  font-weight-bold">

                  {{ moment(refundPayCardInfo.date).local().format("DD-MM-YYYY HH:mm")
                  }}
                </v-col>
                <v-divider vertical></v-divider>
                <v-col class="col-3">
                  {{ "İADE MESAJI :" }}
                </v-col>
                <v-col class="col-3  font-weight-bold">
                  {{ refundPayCardInfo.message }}
                </v-col>
              </v-row>


              <v-row>
                <v-col class="col-3">
                  {{ "İADE EDEN MESAJI" }}
                </v-col>
                <v-col class="col-3 font-weight-bold">
                  {{ refundPayCardInfo.auditsMessage }}
                </v-col>
                <v-divider vertical></v-divider>
                <v-col class="col-3">
                  {{ "ALINAN ÖDEME TİPİ :" }}
                </v-col>
                <v-col class="col-3 font-weight-bold">

                  {{ $store.state.ceviri[refundPayCardInfo.paymentType]
                  }}
                </v-col>
              </v-row>

              <v-row>
                <v-col class="col-3">
                  {{ "İADE EDİLEN TOPLAM TUTAR:" }}
                </v-col>
                <v-col class="col-3 font-weight-bold">
                  {{ refundPayCardInfo.refundPrice }}
                </v-col>
              </v-row>

            </CCardText>
          </v-card> -->

          <v-card elevation="10" class="mb-5" v-if="refundPayCardInfo.CardShow">
            <v-toolbar
              height="40"
              dense
              dark
              class="grey darken-1"
              elevation="0"
            >
              <v-toolbar-title style="font-size: 14px"
                ><strong>
                  {{ refundPayCardInfo.title }}
                </strong></v-toolbar-title
              >
            </v-toolbar>
            <v-card-text class="px-4 pt-2 pb-3">
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <strong>İptal Tarihi :</strong>
                  {{
                    moment(refundPayCardInfo.date)
                      .local()
                      .format("DD-MM-YYYY HH:mm")
                  }}
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  v-if="refundPayCardInfo.paymentType !== `debit`"
                >
                  <strong>İade Edilen Tutar:</strong>
                  {{ refundPayCardInfo.refundPrice }}
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  v-if="refundPayCardInfo.paymentType == `debit`"
                >
                  <strong>İadesi Talep Edilen Tutar:</strong>
                  {{ refundPayCardInfo.reqPrice }}
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <strong>İşlemi Gerçekleştiren:</strong>
                  {{ refundPayCardInfo.personInfo }}{{ "/" }}
                  {{ refundPayCardInfo.departmanRole }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- İADE ÖDEMESİ KART BİTİŞ ---- İADE ÖDEMESİ CARD  END ------->

          <!-- ÖDEMELER BAŞLANGIÇ ------ PAYMENTS START  ----->
          <v-card
            elevation="10"
            class="mb-5"
            v-if="reservationData?.payments?.length > 0"
          >
            <v-toolbar
              height="40"
              dense
              dark
              color="grey darken-1"
              elevation="0"
            >
              <v-toolbar-title style="font-size: 14px"
                ><strong>
                  {{ $t("payments") }}
                </strong></v-toolbar-title
              >
            </v-toolbar>

            <v-simple-table>
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>
                    {{ $t("type") }}
                  </th>
                  <th>
                    {{ $t("channel") }}
                  </th>
                  <th>
                    {{ $t("date") }}
                  </th>
                  <th>ERP</th>
                  <th class="text-right">
                    {{ $t("amount") }}
                  </th>
                  <th
                    v-if="reservationData.consensus.status != 'completed'"
                    class="text-right"
                  >
                    {{ $t("early_return") }}
                  </th>
                  <th
                    v-if="
                      reservationData.status == `active` ||
                      reservationData.status == `completed`
                    "
                    class="text-right"
                  >
                    <!-- {{ $t("early_return") }} -->
                    Parçalı iade
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="payment in reservationData?.payments"
                  :key="payment._id"
                >
                  <td>{{ payment?.orderId }}</td>
                  <td>
                    {{
                      payment?.paymentType == "payment"
                        ? $t("collection")
                        : $t("early_return")
                    }}
                  </td>
                  <td>
                    {{
                      payment?.channel == "pos"
                        ? $t("virtual_pos")
                        : payment?.channel
                    }}
                  </td>

                  <td>
                    {{
                      moment(payment?.created)
                        .local()
                        .format("DD-MM-YYYY HH:mm")
                    }}
                  </td>
                  <td>
                    {{
                      payment?.erpStatus == "pending"
                        ? $t("pending")
                        : payment?.erpStatus == "ok"
                        ? $t("transferred")
                        : $t("error_t")
                    }}
                    <font-awesome-icon
                      v-if="payment.erpError"
                      v-c-tooltip="{
                        content: payment?.erpError,
                        placement: 'top',
                      }"
                      :icon="['fas', 'info-circle']"
                      class="mr-2"
                    />
                  </td>
                  <td
                    class="text-right"
                    style="font-weight: bolder"
                    v-if="payment?.totalAmount"
                  >
                    {{
                      payment?.totalAmount.toLocaleString("tr-TR", {
                        style: "currency",
                        currency: payment?.currency || "TRY",
                      })
                    }}
                  </td>
                  <td>
                    <div class="d-flex justify-end">
                      <RoleProvider slug="RESERVATION_REFUND">
                        <div slot-scope="{ canItPass }">
                          <v-btn
                            v-if="
                              payment.paymentType == 'payment' &&
                              !isPartner &&
                              reservationData.consensus.status != 'completed' &&
                              canItPass
                            "
                            x-small
                            color="error"
                            @click="cacelBtnClick('single')"
                          >
                            {{ $t("early_return_btn") }}
                          </v-btn>
                        </div>
                      </RoleProvider>
                      <RoleProvider slug="RESERVATION_REFUND_PAYMENT">
                        <div slot-scope="{ canItPass }">
                          <v-btn
                            class="ml-1"
                            x-small
                            color="warning"
                            v-if="
                              !isPartner &&
                              payment.paymentType == 'payment' &&
                              (reservationData.status == `active` ||
                                reservationData.status == `approved` ||
                                reservationData.status == `completed`)
                            "
                            @click="refundPaymentModel = true"
                          >
                            KISMİ İADE
                          </v-btn>
                        </div>
                      </RoleProvider>
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
          <!-- ÖDEMELER BİTİŞ ------ PAYMENTS END  ----->

          <!-- POS HAREKETLERİ BAŞLANGIÇ ------ POS TRANSACTİONS START  ----->
          <v-card elevation="10" class="mb-5">
            <v-toolbar
              height="40"
              dense
              dark
              color="grey darken-1"
              elevation="0"
            >
              <v-toolbar-title style="font-size: 14px"
                ><strong>
                  {{ $t("pos_transactions") }}
                </strong></v-toolbar-title
              >
            </v-toolbar>

            <v-simple-table dense>
              <thead>
                <tr>
                  <th>
                    {{ $t("process_t") }}
                  </th>
                  <th>
                    {{ $t("date") }}
                  </th>
                  <th>
                    {{ $t("message") }}
                  </th>
                  <th>
                    {{ $t("card") }}
                  </th>
                  <th>
                    {{ $t("amount") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="bankitem in reservationData.bankTransactions"
                  :key="bankitem._id"
                  :class="
                    bankitem.type === 'paymentError' ||
                    bankitem.type === 'paymentFail'
                      ? 'text-danger'
                      : 'text-success'
                  "
                >
                  <td>
                    {{
                      bankitem.langName
                        ? bankitem.langName.TR || bankitem.langName.tr
                        : bankitem.type
                    }}
                  </td>
                  <td>
                    {{
                      bankitem.createdAt
                        | moment("add", "3 hours", "DD-MM-YYYY HH:mm:ss")
                    }}
                  </td>
                  <td>{{ bankitem.code }} {{ bankitem.message }}</td>
                  <td>{{ bankitem.data.CardNumber }}</td>
                  <td>
                    {{
                      bankitem.data?.TotalAmount &&
                      Number(bankitem.data?.TotalAmount)?.toLocaleString(
                        "tr-TR",
                        {
                          style: "currency",
                          currency: bankitem.data?.Currency || "TRY",
                        }
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
          <!-- POS HAREKETLERİ BİTİŞ ------ POS TRANSACTİONS END  ----->

          <!-- ACENTE NOTLARI BAŞLANGIÇ ------ AGENT NOTES START  ----->
          <v-card elevation="10" class="mb-5">
            <v-toolbar
              height="40"
              dense
              dark
              color="grey darken-1"
              elevation="0"
            >
              <v-toolbar-title style="font-size: 14px"
                ><strong>
                  {{ $t("agent_note") }}
                </strong></v-toolbar-title
              >
            </v-toolbar>
            <AgentNote
              :_id="reservationData._id"
              :slug="'reservations'"
              :total="agentNotesTotal"
            />
          </v-card>
          <!-- ACENTE NOTLARI BİTİŞ ------ AGENT NOTES END  ----->

          <!-- REZERVASYON ANKETİ BAŞLANGIÇ ------ REZERVATİON SURVET START  ----->
          <v-card
            elevation="10"
            class="mb-5"
            v-if="reservationData.status == 'completed'"
          >
            <v-toolbar
              height="40"
              dense
              dark
              color="grey darken-1"
              elevation="0"
            >
              <v-toolbar-title style="font-size: 14px"
                ><strong>
                  {{ $t("reservation_survey") }}
                </strong></v-toolbar-title
              >
            </v-toolbar>

            <Comment
              :_id="reservationData._id"
              :reservationCode="reservationData.reservationCode"
              :slug="'reservations'"
            />
          </v-card>
        </v-col>
      </v-row>

      <!-- REZERVATİON CANCELLATION MODAL START  ----->
      <CModal
        close-on-backdrop
        :title="`${reservationData.reservationCode}  ${$t('rejection_desc3')}`"
        size="lg"
        :show.sync="reservastionCancelModal"
      >
        <v-row
          v-if="
            reservationData.searchPrice?.vendor?.freeCancelInfo?.minHoursInfo
          "
        >
          <v-alert
            dense
            outlined
            type="warning"
            style="width: 100%; color: white"
          >
            {{
              reservationData.searchPrice?.vendor?.freeCancelInfo?.fullPayCut
                ? $t("free_cancel_alert_2", {
                    minHoursInfo:
                      reservationData.searchPrice?.vendor?.freeCancelInfo
                        ?.minHoursInfo,
                  })
                : $t("free_cancel_alert", {
                    minHoursInfo:
                      reservationData.searchPrice?.vendor?.freeCancelInfo
                        ?.minHoursInfo,
                    payCutDay:
                      reservationData.searchPrice?.vendor.freeCancelInfo
                        ?.payCutDay,
                  })
            }}
          </v-alert>
        </v-row>
        <v-row>
          <v-col>
            <h3>
              {{ $t("rejection_desc") }}
            </h3>
          </v-col>
        </v-row>

        <v-row form class="form-group">
          <v-col>
            <CInputRadio
              v-for="option in reasonsOfCancel"
              :key="option._id"
              name="reasonCancel"
              :label="option.question"
              :value="option.question"
              @update:checked="
                () => {
                  (reasonCancel.message = option.question),
                    (reasonCancel.vendorRulesAccepted =
                      option.vendorRulesAccepted);
                  reasonCancel.otherMessage = 0;
                }
              "
            />
            <CInputRadio
              name="reasonCancel"
              :label="$t('other')"
              @update:checked="() => (reasonCancel.otherMessage = 1)"
            />

            <div v-if="$store.state?.GetServerData?.userType === 'admin'">
              <v-checkbox
                v-model="reasonCancel.isCancelwithRefund"
                label="Manuel İade Yap"
                @change="
                  !reasonCancel.isCancelwithRefund
                    ? (reasonCancel.refundPrice = 0)
                    : null
                "
              ></v-checkbox>
              <v-text-field
                v-if="reasonCancel.isCancelwithRefund"
                type="number"
                label="İade Tutarı"
                v-model="reasonCancel.refundPrice"
                :max="reservationData?.prices?.totalPrice?.TRY"
                :rules="[
                  (v) =>
                    v <= reservationData?.prices?.totalPrice?.TRY ||
                    'İade tutarı toplam fiyatı geçemez',
                  (v) => v > 0 || 'İade tutarı 0\'dan büyük olmalıdır',
                ]"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="reasonCancel.otherMessage == 1">
          <v-col>
            <CTextarea v-model="otherReasonCancel" :placeholder="$t('leave')" />
          </v-col>
        </v-row>
        <template #footer>
          <CButton
            @click="reservastionCancelModal = false"
            :disabled="statusOfCancellation"
            >{{ $t("leave") }}</CButton
          >
          <CButton
            color="danger"
            :disabled="statusOfCancellation"
            @click="reservationStatusUpdate('cancelled')"
            >{{ $t("send") }}
          </CButton>
        </template>
      </CModal>
      <!--REZERVATİON CANCELLATION MODAL END  ----->

      <!-- EARLY RETURN  MODAL START  ----->
      <!-- <CModal
        close-on-backdrop
        :title="
          `${reservationData.reservationCode} ` + $t('early_return_info2')
        "
        size="lg"
        :show.sync="earlyReturnModal"
      >
        <v-row>
          <v-col>
            <h3>{{ $t("early_return_info3") }}</h3>
          </v-col>
        </v-row>

        <v-row form class="form-group">
          <v-col>
            <CInputRadio
              v-for="option in reasonsOfEarlyReturn"
              :key="option._id"
              name="reasonCancel"
              :label="option.question"
              :value="option.question"
              @update:checked="() => (reasonEarlyReturn = option.question)"
            />
            <CInputRadio
              name="reasonCancel"
              :label="$t('other')"
              @update:checked="() => (openEarlyTextarea = true)"
            />
          </v-col>
        </v-row>
        <v-row v-if="openEarlyTextarea">
          <v-col>
            <CTextarea
              v-model="reasonEarlyReturn"
              :placeholder="$t('your_explanation')"
            />
          </v-col>
        </v-row>
        <template #footer>
          <CButton @click="earlyReturnModal = false">
            {{ $t("leave") }}
          </CButton>
          <CButton color="danger" @click="earlyReturnRequest()">
            {{ $t("send") }}
          </CButton>
        </template>
      </CModal> -->
      <!--  EARLY RETURN  MODAL END  ----->

      <!-- EARLY  RETURN REGISTRATION FORM  MODAL START  ----->

      <CModal
        :close-on-backdrop="false"
        size="lg"
        :show.sync="formEarlyReturnRegistrationModal"
        v-if="reservationData.carDeliveryReturn"
        title="Erken İade Talebi"
      >
        <v-row v-if="reservationData.vendor?.noEarlyReturn">
          <v-alert
            dense
            outlined
            type="error"
            style="width: 100%; color: white"
          >
            {{
              $t("early_return_alert", {
                vendorName: reservationData?.vendor?.brand,
              })
            }}
          </v-alert>
        </v-row>

        <v-row>
          <v-alert
            dense
            outlined
            type="warning"
            style="width: 100%; color: white"
          >
            Erken iade işleminde müşterinin yararlandığı tüm indirim ve kampanya
            faydaları iptal edilir.
          </v-alert>
        </v-row>
        <v-row form class="form-group">
          <v-col>
            <label class="font-weight-bold">Erken İade Sebebi</label>
            <CInputRadio
              v-for="option in reasonsOfEarlyReturn"
              :key="option._id"
              name="reasonCancel"
              :label="option.question"
              :value="option.question"
              @update:checked="() => (reasonEarlyReturn = option.question)"
            />
            <CInputRadio
              name="reasonCancel"
              :label="$t('other')"
              @update:checked="() => (openEarlyTextarea = true)"
            />
          </v-col>
        </v-row>
        <v-row v-if="openEarlyTextarea">
          <v-col>
            <CTextarea
              v-model="reasonEarlyReturn"
              :placeholder="$t('your_explanation')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <label class="font-weight-bold">Erken İade Edilecek Tarih</label>
            <VueCtkDateTimePicker
              only-date
              no-header
              :minDate="
                moment(reservationData.carDeliveryReturn.delivery.datetime)
                  .add(1, 'days')
                  .format('YYYY-MM-DD')
              "
              :maxDate="
                moment(reservationData.carDeliveryReturn.return.datetime)
                  .subtract(1, 'days')
                  .format('YYYY-MM-DD')
              "
              format="YYYY-MM-DD"
              formatted="l"
              v-model="earlyReturnDate"
              :locale="locale"
              :label="$t('returned_date')"
              auto-close
            />
          </v-col>
        </v-row>
        <v-col>
          <v-checkbox
            v-model="isManuelEarlyReturnPayment"
            @change="earlyReturnNewDatas.calculatedRefundPrice = 0"
            label="Manuel İade Tutarı"
          ></v-checkbox>
        </v-col>
        <v-col v-if="isManuelEarlyReturnPayment">
          <label class="font-weight-bold">Manuel İade Tutarı </label>
          <CInput
            v-model.number="earlyReturnPaymentAmount"
            type="number"
            @input="calculateEarlyRefundPrice"
          ></CInput>
          <small style="color: red"
            >(!!!Müşteriye İade edilecek tutar bilgisidir.İade edilecek tutarda
            müşterinin faydalandığı kampanyalar yok sayılarak girilen tutar aide
            edilecektir.)</small
          >
        </v-col>
        <v-col v-else>
          <label class="font-weight-bold"
            >Tedarikçiden Alınan Toplam İade Tutarı <br /><small
              style="color: red"
              >(!!!Müşteriye İade edilecek bilgi değildir. Tedarikçi tarafından
              belirtilen toplam iade bedelini giriniz. Müşteriye iade edilecek
              tutar aşağıda Hesaplanan İade alanında gözükecektir.)</small
            ></label
          >
          <CInput
            v-model.number="earlyReturnPaymentAmount"
            type="number"
            @input="calculateEarlyRefundPrice"
          ></CInput>
        </v-col>
        <v-row v-if="earlyReturnNewDatas.days > 0">
          <v-col class="col-4">
            <strong>Yeni Toplam Gün</strong><br />
            <span style="font-size: 30px; font-weight: bolder">{{
              earlyReturnNewDatas.days
            }}</span>
          </v-col>
          <v-col class="col-4">
            <strong>İade Edilen Gün</strong><br />
            <span style="font-size: 30px; font-weight: bolder">{{
              earlyReturnNewDatas.daysRefunded
            }}</span>
          </v-col>
          <v-col class="col-4">
            <strong>Hesaplanan İade Tutarı:</strong><br />
            <span style="font-size: 30px; font-weight: bolder">{{
              func
                .toMoneyFormat(earlyReturnNewDatas.calculatedRefundPrice)
                .toLocaleString("tr", {
                  style: "currency",
                  currency: "TRY",
                })
            }}</span>
          </v-col>
        </v-row>
        <v-row
          v-if="
            reservationData?.searchPrice?.pricing?.campaignData &&
            reservationData?.searchPrice?.pricing?.campaignData !== null
          "
        >
          <strong
            >İptal Edilen Kampanya :{{
              reservationData.searchPrice.pricing?.campaignData?.info?.name
            }}</strong
          >
          <strong
            v-if="
              reservationData.searchPrice.pricing?.campaignData?.discountType ==
              `rate`
            "
            >İptal Edilen Kamp. İndirimi %:{{
              reservationData.searchPrice.pricing?.campaignData.discountRate
            }}</strong
          >
          <strong
            v-if="
              reservationData.searchPrice.pricing?.campaignData?.discountType ==
              `amount`
            "
            >İptal Edilen Kamp. İndirimi TL:{{
              reservationData.searchPrice.pricing?.campaignData.discountAmount
            }}</strong
          >
          <strong
            v-if="
              reservationData.searchPrice.pricing?.campaignData?.discountType ==
              `day`
            "
            >İptal Edilen Kamp. İndirimi Gün:{{
              reservationData.searchPrice.pricing?.campaignData.discountDay
            }}</strong
          >
        </v-row>

        <template #footer>
          <CButton
            @click="formEarlyReturnRegistrationModal = false"
            :disabled="statusOfEarlyPayment"
          >
            {{ $t("leave") }}
          </CButton>
          <CButton
            color="danger"
            style="color: white"
            :disabled="earlyReturnCalcDays == 0 || reasonEarlyReturn.length < 1"
            @click="earlyReturnRegistration()"
          >
            {{ $t("send") }}
          </CButton>
        </template>
      </CModal>
      <!-- EARLY  RETURN REGISTRATION FORM  MODAL   END  ----->

      <!--PAYMENT MODAL START  ----->

      <CModal
        size="lg"
        class="vendorModalAddUpdateForm modalNoBorder"
        title="Ödeme"
        :show.sync="paymentModalShow"
        :close-on-backdrop="false"
      >
        <CCard class="p-0 m-0 cardShadow">
          <CCardBody>
            <validation-observer ref="refValidationNoneSecure">
              <CContainer class="p-4 m-0">
                <v-row>
                  <v-col>
                    <CInput
                      v-mask="'#### #### #### ####'"
                      :placeholder="$t('card_number2')"
                      :label="$t('card_number2')"
                      v-model="paymentNoneSecureForm.cardNumber"
                      :value="paymentNoneSecureForm.cardNumber"
                      ref="cardNumber"
                    />
                    <CInput
                      type="text"
                      :placeholder="$t('owner_of_the_card_number')"
                      :label="$t('owner_of_the_card_number')"
                      v-model="paymentNoneSecureForm.cardOwner"
                      :value="paymentNoneSecureForm.cardOwner"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <CInput
                      v-model="paymentNoneSecureForm.expiry"
                      placeholder="AA/YY"
                      :label="$t('month') + '/' + $t('year')"
                      v-mask="`##/##`"
                    />
                  </v-col>

                  <v-col>
                    <CInput
                      v-model="paymentNoneSecureForm.cvv"
                      :placeholder="$t('secure_code')"
                      :label="$t('secure_code')"
                      v-mask="`###`"
                    />
                  </v-col>
                </v-row>
              </CContainer>
            </validation-observer>
          </CCardBody>
        </CCard>

        <template #footer>
          <CButton
            @click="makeNoneSecurePayment"
            :disabled="createPaymentBtnLoading"
            color="success"
          >
            <font-awesome-icon
              :icon="['fas', 'circle-notch']"
              size="lg"
              class="spin mr-2"
              v-show="createPaymentBtnLoading"
            />
            {{ $t("complete_payment") }}
          </CButton>
        </template>
      </CModal>
      <!-- PAYMENT MODAL END  ----->

      <!-- REZERVATİON SETTİNGS   MODAL START  ----->
      <CModal
        close-on-backdrop
        :title="$t('reservation_settings')"
        size="lg"
        :show.sync="reservationSetting.modalShow"
      >
        <v-col class="bil__type" col="12" lg="6">
          <CSelect
            nam="payment-type"
            :value.sync="reservationData.paymentType"
            :options="reservationSetting.typesOfPaymentAvailableOptions"
            :label="$t('payment_type')"
            :placeholder="$t('select_payment_type')"
          />
        </v-col>
        <v-col class="bil__type" col="12" lg="6" v-if="!isPartner">
          <CSelect
            :value.sync="reservationData.spendingObjection"
            :options="reservationSetting.spendingObjectionOptions"
            :label="$t('spending_objection')"
            :placeholder="$t('select_spending_objection')"
          />
        </v-col>

        <template #footer>
          <CButton @click="reservationSetting.modalShow = false">
            {{ $t("leave") }}
          </CButton>
          <CButton
            @click="reservationUpdate()"
            :disabled="reservationSetting.modalLoading"
          >
            <font-awesome-icon
              :icon="['fas', 'circle-notch']"
              size="lg"
              class="spin mr-2"
              v-show="reservationSetting.modalLoading"
            />
            {{ $t("update") }}
          </CButton>
        </template>
      </CModal>
      <!--  REZERVATİON SETTİNGS   MODAL END  ----->

      <!--   REFUND PAYMENT  MODAL START  ----->

      <CModal
        close-on-backdrop
        :title="
          `${reservationData.reservationCode} ` +
          'Numaralı Rezervasyona Ücret İadesi Yapıyorsunuz.'
        "
        size="lg"
        :show.sync="refundPaymentModel"
        v-if="reservationData.carDeliveryReturn"
      >
        <v-tabs v-model="tab" :show-arrows="false">
          <v-tab @click="typeOfRefundPayment = 'PARTIAL'">
            {{ $t("partial_refund_fee_payment") }}
          </v-tab>
          <v-tab @click="typeOfRefundPayment = 'TOTAL'">
            {{ $t("full_refund_fee_payment") }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="mt-4">
          <v-tab-item>
            <v-row>
              <v-col>
                <CInput
                  :label="$t('refund_payment_amount')"
                  class="font-weight-bold"
                  v-model.number="partialRefundPayment"
                  type="number"
                />
                <span
                  style="color: red; font-weight: bold"
                  v-if="
                    !(
                      partialRefundPayment <=
                      reservationData.prices?.payNow?.TRY
                    )
                  "
                  >Kısmi Ücret Tutarı Tam ücret Tutarından Fazla Olamaz.</span
                >
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row>
              <v-col>
                <CInput
                  :label="$t('refund_payment_amount')"
                  class="font-weight-bold"
                  :readonly="true"
                  v-model.number="reservationData?.prices?.payNow.TRY"
                  type="number"
                />
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>

        <template #footer>
          <CButton
            @click="(refundPaymentModel = false), (partialRefundPayment = 0)"
          >
            {{ $t("leave") }}
          </CButton>

          <CButton
            v-if="partialRefundPayment <= reservationData.prices?.payNow?.TRY"
            color="secondary"
            @click="refundPayment()"
          >
            {{ $t("send") }}
          </CButton>
        </template>
      </CModal>
      <!--   REFUND PAYMENT  MODAL END  ----->

      <!--  NOSHOW REFUND PAYMENT  MODAL START  ----->

      <CModal
        close-on-backdrop
        :title="`${reservationData.reservationCode} ` + 'No Show'"
        size="lg"
        :show.sync="noShowRefundPaymentModel"
      >
        <v-row v-if="reservationData.searchPrice?.vendor.noNoShowReturn">
          <v-alert
            dense
            outlined
            type="warning"
            style="width: 100%; color: white"
          >
            {{
              $t("no_show_cancel_alert", {
                vendorName: reservationData.searchPrice?.vendor?.name,
              })
            }}
          </v-alert>
        </v-row>
        <v-tabs v-model="tab" :show-arrows="false">
          <v-tab @click="typeOfNoShowRefundPayment = 'PARTIAL'">
            {{ $t("partial_refund_fee_payment") }}
          </v-tab>
          <v-tab @click="typeOfNoShowRefundPayment = 'TOTAL'">
            {{ $t("full_refund_fee_payment") }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="mt-4">
          <v-tab-item>
            <v-row>
              <v-col>
                <CInput
                  :label="$t('refund_payment_amount')"
                  class="font-weight-bold"
                  v-model.number="partialNoShowRefundPayment"
                  type="number"
                  :disabled="typeOfNoShowRefundPayment == `justNoShow`"
                />
                <span
                  style="color: red; font-weight: bold"
                  v-if="
                    !(
                      partialNoShowRefundPayment <=
                      reservationData.prices?.payNow?.TRY
                    )
                  "
                  >İade Tutarı ödenen tutardan fazla olamaz.</span
                >
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row>
              <v-col>
                <CInput
                  :label="$t('refund_payment_amount')"
                  class="font-weight-bold"
                  :readonly="true"
                  v-model.number="reservationData.prices.payNow.TRY"
                  type="number"
                  :disabled="typeOfNoShowRefundPayment == `justNoShow`"
                />
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
        <v-checkbox
          v-model="typeOfNoShowRefundPayment"
          label="İade Yapma"
          color="red"
          value="justNoShow"
          hide-details
        ></v-checkbox>

        <span
          v-if="typeOfNoShowRefundPayment == `justNoShow`"
          style="color: red; font-weight: bold"
          >Rezervasyonun durumu NoShow olarak değiştirilecek ve herhangi bir
          iade ödemesi yapılmayacaktır.<br />
          Müşteriye mail veya sms gönderimi yapılmayacaktır.</span
        >

        <template #footer>
          <CButton
            @click="
              (noShowRefundPaymentModel = false),
                (partialNoShowRefundPayment = 0)
            "
          >
            {{ $t("leave") }}
          </CButton>

          <CButton
            v-if="
              partialNoShowRefundPayment <= reservationData.prices?.payNow?.TRY
            "
            color="secondary"
            @click="noShowRefundPayment()"
          >
            {{ $t("send") }}
          </CButton>
        </template>
      </CModal>
      <!--  NOSHOW REFUND PAYMENT  MODAL END  ----->
      <v-dialog width="800" persistent v-model="resCancelReflectionModal">
        <v-card>
          <v-toolbar color="primary" dark dense>
            <v-toolbar-title>İptal Bilgileri Yansıt</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn @click="resCancelReflectionModal = false" icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items></v-toolbar
          >
          <v-card-text class="mt-4">
            <v-row form class="form-group">
              <v-col>
                <CInputRadio
                  v-for="option in reasonsOfCancel"
                  :key="option._id"
                  name="reasonCancel"
                  :label="option.question"
                  :value="option.question"
                  @update:checked="
                    () => {
                      resCancelReflectionForm.refund.message = option.question;
                    }
                  "
                />
                <CInputRadio
                  name="reasonCancel"
                  :label="$t('other')"
                  @update:checked="() => (reasonCancel = 1)"
                />
              </v-col>
            </v-row>
            <v-row v-if="reasonCancel == 1">
              <v-col>
                <CTextarea
                  v-model="resCancelReflectionForm.refund.message"
                  :placeholder="$t('leave')"
                />
              </v-col>
            </v-row>
            <v-row no-gutters v-if="!isUserRefundReflection">
              <v-col cols="12" class="mt-4">
                <v-select
                  dense
                  hide-details
                  v-model="cancellationAdminInfo"
                  :items="cancellationAdminInfoData"
                  item-text="fullname"
                  :return-object="true"
                  label="İptal Eden Kişi"
                  outlined
                  @change="addResCancelInfo"
                ></v-select>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-checkbox
                v-model="isUserRefundReflection"
                label="Kullanıcı İptali"
              ></v-checkbox>
            </v-row>

            <v-col cols="3">
              <v-btn
                @click="addResCancelReflection"
                color="green lighten-1"
                small
                dark
                >Yansıt</v-btn
              >
            </v-col>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog width="400" persistent v-model="addExtrasModal">
        <v-card>
          <v-toolbar color="primary" dark dense>
            <v-toolbar-title>Yansıtma Ekle</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn @click="addExtrasModal = false" icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items></v-toolbar
          >
          <v-card-text class="mt-4">
            <v-row v-for="(item, index) in addExrasItems" :key="index">
              <v-col cols="9">
                <v-text-field
                  type="number"
                  step="1"
                  min="1"
                  max="1000000"
                  :label="item.name"
                  dense
                  hide-details
                  v-model.number="item.totalPrice.TRY"
                />
              </v-col>
              <v-col cols="3">
                <v-btn
                  :disabled="item.totalPrice.TRY <= 0"
                  @click="addExtras(item)"
                  color="green lighten-1"
                  small
                  dark
                  >Ekle</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="viewApiLogs" style="z-index: 9999999" width="auto">
        <v-card>
          <v-toolbar dark dense>
            <v-toolbar-title>API Logları</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn @click="viewApiLogs = false" icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items></v-toolbar
          >
          <v-card-text class="mt-5">
            <vue-json-pretty :data="detailApiLogs" />
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog width="400" v-model="extraUpdateModal">
        <v-card>
          <v-toolbar color="primary" dark dense>
            <v-toolbar-title>Yansıtma Güncelle</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn @click="extraUpdateModal = false" icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items></v-toolbar
          >
          <v-card-text class="mt-4" v-if="extraUpdateData?.code">
            <v-row>
              <v-col cols="8">
                <v-text-field
                  type="number"
                  step="1"
                  min="1"
                  max="1000000"
                  :label="extraUpdateData.name"
                  dense
                  hide-details
                  v-model="extraUpdateData.totalPrice.TRY"
                />
              </v-col>
              <v-col cols="4">
                <v-btn
                  :disabled="extraUpdateData.totalPrice.TRY <= 0"
                  @click="updateExtras(extraUpdateData)"
                  color="green lighten-1"
                  small
                  dark
                  >Güncelle</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              x-small
              dark
              color="red"
              class="ml-2"
              @click="deleteExtras(extraUpdateData)"
              >SİL</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <gogo-loading v-else />

    <v-dialog
      close-on-back
      v-model="approvedExtrasForVendorModal"
      max-width="500"
    >
      <v-card>
        <v-card-text class="d-flex justify-center">
          <div class="mt-2">
            <h3 class="font-weight-bold">
              {{ reservationData?.reservationCode }} Fatura Onayı Verilsin mi?
            </h3>
            <h5>
              <span class="red--text"
                >Bu işlem sonrasında rezervasyon girdiğiniz bilgiler
                doğrultusunda faturalandırılacaktır.</span
              >
            </h5>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex">
          <v-col>
            <v-btn
              block
              @click="approvedExtrasForVendorModal = false"
              class="primary"
              small
              >VAZGEÇ</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              dark
              block
              @click="setApproved()"
              color="green lighten-1"
              small
              >ONAYLIYORUM</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue, { isRef } from "vue";
import axios from "axios";
import moment, { isDate } from "moment";
import Func from "../../func";
import GogoLoading from "../../components/GogoLoading.vue";
import AgentNote from "@/components/AgentNote";
import Comment from "@/components/Comment";
import { IMaskComponent } from "vue-imask";
import { RoleProvider } from "../../provider";
import DateCheck from "../../components/DateCheck.vue";

import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";

var _ = require("lodash");
const defaultPaymentTypes = [
  { label: "Sanal Pos", value: "noneSecurePos" },
  { label: "SMS Linki İle Ödeme", value: "smsPos" },
  { label: "Cari Hesap", value: "debit" },
  // { label: "ivrPos", value: "ivrPos" }, //sonradan gelecek
  // { label: "3dPos", value: "3dPos" },
];
export default {
  name: "ReservationDetail",
  components: {
    VueJsonPretty,
    AgentNote,
    Comment,
    GogoLoading,
    "imask-input": IMaskComponent,
    RoleProvider,
    DateCheck,
  },
  data() {
    return {
      apiURL: process.env.VUE_APP_API_URL,
      rules: {
        required: (value) => !!value || "Zorunlu alan.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Geçersiz e-mail";
        },
        phone: (value) => {
          const pattern =
            /^[\+]?[(]?[0-9]{2,4}[)]?[-\s]?([0-9]{3}[-\s]?){2}[0-9]{2}([-\s]?[0-9]{2})?$/;
          return pattern.test(value) || "Geçersiz telefon.";
        },
        identity: (value) => {
          let pattern;
          if (this.driverInfoUpdateForm.identity.certificateType === "tc") {
            pattern = /^[1-9]{1}[0-9]{9}[02468]{1}$/;
            return pattern.test(value) || "Geçersiz T.C. Kimlik Numarası";
          } else {
            pattern = /^.{5,}$/;
            return pattern.test(value) || "Geçersiz Pasaport Numarası";
          }
        },
      },
      tab: null,
      locale: this.$i18n.locale,
      invoiceDetail: {},
      earlyReturnNewDatas: {},
      reservationSetting: {
        modalShow: false,
        modalLoading: false,
        typesOfPaymentAvailableOptions: [],
        spendingObjectionOptions: [
          {
            label: this.$t("there_is"),
            value: true,
          },
          {
            label: this.$t("none"),
            value: false,
          },
        ],
        updateBody: {},
      },
      reservationCancellationDate: "",
      //----------payment
      paymentModalShow: false,
      paymentType: "",
      createPaymentBtnLoading: false,

      paymentNoneSecureForm: {
        reservationCode: "",
        cardNumber: "",
        cvv: "",
        expiry: "",
        cardOwner: "",
      },
      //----------payment
      updatedExtrasValue: false,
      addExrasItems: [],
      addExrasItemsModel: [
        {
          name: "HGS Yansıtma",
          totalPrice: {
            TRY: 0,
          },
          code: "HGS_Manuel",
        },
        {
          name: "Yakıt Yansıtma",
          totalPrice: {
            TRY: 0,
          },
          code: "YKT_Manuel",
        },
        {
          name: "Sigorta Yansıtma",
          totalPrice: {
            TRY: 0,
          },
          code: "SGRT_Manuel",
        },
        {
          name: "Hasar Yansıtma",
          totalPrice: {
            TRY: 0,
          },
          code: "HSR_Manuel",
        },
        {
          name: "Trafik Cezası Yansıtma",
          totalPrice: {
            TRY: 0,
          },
          code: "TCY_Manuel",
        },

        {
          name: "Diğer Yansıtma",
          totalPrice: {
            TRY: 0,
          },
          code: "OTHER_Manuel",
        },
      ],
      // selectedItemForAddExtra: [],
      approvedExtrasForVendorModal: false,
      addExtrasModal: false,
      viewApiLogs: false,
      detailApiLogs: [],
      cancelBtnType: "",
      reasonCancel: {
        message: "",
        vendorRulesAccepted: false,
        refundPrice: 0,
        isCancelwithRefund: false,
        otherMessage: 0,
      },
      otherReasonCancel: "",
      reasonsOfCancel: [],
      statusOfCancellation: false,
      reservastionCancelModal: false,
      refundPaymentModel: false,
      noShowRefundPaymentModel: false,
      reasonEarlyReturn: "",
      openEarlyTextarea: false,
      otherReasonEarlyReturn: "",
      reasonsOfEarlyReturn: [],
      earlyReturnModal: false,
      statusOfEarlyPayment: false,
      earlyReturnDate: null,
      typeOfRefundPayment: "PARTIAL",
      typeOfEarlyReturnPayment: "DAILY",
      typeOfNoShowRefundPayment: "PARTIAL",
      earlyReturnPaymentAmount: 0,
      earlyReturnCalcDays: 0,
      calculatedRefundPrice: 0,
      isManuelEarlyReturnPayment: false,
      partialRefundPayment: 0,
      partialNoShowRefundPayment: 0,
      formEarlyReturnRegistrationModal: false,
      bankTransactions: {},
      agentNotesTotal: 0,
      pageLoading: false,
      status: false,
      func: Func,
      ENV_URL_VENDOR: process.env.VUE_APP_CDN_URL_VENDOR,
      ENV_URL_CARS: process.env.VUE_APP_CDN_URL_CARS,
      carCollapsed: true,
      memberCollapsed: true,
      driverCollapsed: true,
      rentalCollapsed: true,
      pricingCollapsed: true,
      reservationCode: this.$route.params.reservationCode,
      reservationDateTime: "",
      locationList: [],
      locationListSelectOptions: [],
      selectedDeliveryLocationSlug: "",
      selectedReturnLocationSlug: "",
      driverInfoUpdate: false,
      oldIdentityValue: null,
      invoiceUpdate: false,
      invoiceTypeValue: [
        {
          value: "individual",
          tr: "Bireysel",
          en: "Individual",
        },
        {
          value: "individualCompany",
          tr: "Bireysel Şirket",
          en: "IndividualCompany",
        },
        {
          value: "company",
          tr: "Şirket",
          en: "Company",
        },
        {
          value: "abroad",
          tr: "Yabancı Şirket",
          en: "Abroad",
        },
      ],
      invoiceTransferStatusValue: [
        {
          value: "ok",
          tr: "Tamamlandı",
          en: "IndividualCompany",
        },
        {
          value: "pending",
          tr: "Bekliyor",
          en: "Pending",
        },
        {
          value: "error",
          tr: "Hatalı",
          en: "Error",
        },
      ],
      driverIdentityValue: [
        {
          value: "tc",
          tr: "T.C. Kimlik Numarası",
          en: "T.C. Identity Number",
        },
        {
          value: "passport",
          tr: "Pasaport Numarası",
          en: "Passport Number",
        },
      ],
      // driverInfoUpdateForm: {
      //   name: this.reservationData.driverInfo.name,
      //   lastname: "",
      //   phone: "",
      //   email: "",
      //   passportId: "",
      //   tcId: "",
      //   defaultIdType: 1,
      //   checkboxTI: null,
      //   identity: {
      //     certificateType: "",
      //     value: "",
      //   },
      //   birthday: null,
      //   permissions: [],
      //   filghtNo: "",
      // },
      driverInfoUpdateForm: {},
      invoiceUpdateForm: {},
      invoiceERPForm: {},
      invoiceResetForm: {
        invoiceType: "",
        individual: {
          province: "",
          postCode: "",
          address: "",
        },
        individualCompany: {
          firstname: "",
          lastname: "",
          taxOffice: "",
          taxNumber: "",
          province: "",
          postCode: "",
          address: "",
        },
        company: {
          title: "",
          taxOffice: "",
          taxNumber: "",
          province: "",
          postCode: "",
          address: "",
        },
        abroad: {
          title: "",
          taxNumber: "",
          taxOffice: "",
          country: "",
          province: "",
          postCode: "",
          address: "",
        },
      },
      pricing: {
        totalExtrasPrice: 0,
      },

      reservationData: null,
      reservationDateUpdateModal: false,
      reservationDateUpdateForm: {
        newDate: null,
        newVendorPrice: 0,
        newRentalPrice: 0,
      },
      extraUpdateData: {},
      extraUpdateModal: false,
      resCancelReflectionModal: false,
      resCancelReflectionForm: {
        refund: {
          status: true,
          message: "",
          place: "AGENT_NORMAL",
        },
        audits: {
          departmanRole: "",
          description: "Rezervasyonun tipi değiştirildi.",
          type: "STATUS_CANCELLED",
          author: {},
          place: "AGENT",
          date: null,
        },
        // isPaymentsReflection: false,
      },
      isUserRefundReflection: false,
      cancellationAdminInfo: null,
      cancellationAdminInfoData: [],
    };
  },
  async created() {
    if (this.$route.query.paymentType == "noneSecurePos") {
      this.paymentModalShow = true;
    }
    if (this.reservationCode === "") {
      let routeData = this.$router.resolve({
        name: "Rezervasyonlar",
      });

      window.location.replace(routeData.href);

      return;
    }
    await this.reservationGetDetail(); // Rezervasyon detayı çekiliyor
    this.getLocationList(); // Lokasyon listesi çekiliyo r
    this.getReasonsOfCancel();
    this.getReasonsOfEarlyReturn();
    this.setPaymentTypes();
  },

  computed: {
    cancelRezCarInfo() {
      const reservation = this.reservationData;
      // if (reservation?.refund?.status === true &&
      //   ["AGENT_NORMAL", "CUSTOMER_NORMAL", "PARTNER_NORMAL"].includes(reservation?.refund?.place)
      // )

      if (
        reservation?.refund?.status === true &&
        reservation?.status == "cancelled"
      ) {
        const audits = _.find(this.reservationData.audits, {
          type: "STATUS_CANCELLED",
        });
        const refundPlace = this.reservationData.refund.place;
        var refundPlaceName = "";
        if (refundPlace) {
          if (refundPlace == "CUSTOMER_NORMAL") {
            refundPlaceName = "Kullanıcı/Müşteri";
          }
          if (refundPlace == "PARTNER_NORMAL") {
            refundPlaceName = "Partner";
          }
          if (refundPlace == "AGENT_NORMAL") {
            refundPlaceName = "Agent";
          }
        }
        return {
          CardShow: true,
          title: "İPTAL BİLGİLERİ",
          type: "İPTAL",
          person: audits?.place,
          personInfo: audits?.author?.fullname,
          departmanRole: audits?.departmanRole,
          date: audits?.date,
          place: refundPlaceName,
          message: reservation?.refund?.message,
          refundPrice:
            reservation?.paymentType !== "debit"
              ? _.sumBy(this.reservationData.payments, (payment) =>
                  payment.paymentType === "refund" ? payment?.totalAmount : 0
                ) || 0 + " TL - CARİ ÖDEME"
              : 0 + " TL - CARİ ÖDEME",
        };
      }
      return {};
    },
    partnerBrand() {
      if (this.reservationData.partnerDetail)
        return this.reservationData.partnerDetail.brand;
    },

    // resUpdateRole() {
    //   if (
    //     this.$store.state.GetServerData.signularRules.includes(
    //       "RESERVATION_UPDATE"
    //     )
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    noShowRefundCardInfo() {
      const reservation = this.reservationData;
      if (
        reservation?.noShowRefundPayments?.status === true &&
        ["NOSHOW_REFUND_PAYMENT"].includes(reservation?.refund?.place)
      ) {
        const audits = _.find(this.reservationData.audits, {
          type: "NOSHOW_REFUND_PAYMENT_SUCCESS",
        });
        return {
          CardShow: true,
          title: "NOSHOW İPTAL BİLGİLERİ",
          type: "NOSHOW",
          person: audits?.place,
          personİnfo: audits?.author?.fullname,
          departmanRole: audits?.departmanRole,
          date: audits?.date,
          message: reservation?.refund?.message,
          auditsMessage: audits?.description,
          paymentType: reservation?.paymentType,
          refundPrice:
            reservation?.paymentType !== "debit"
              ? reservation?.payments
                  .reduce(
                    (total, item) =>
                      item.paymentType === "refund"
                        ? total + item.totalAmount
                        : total,
                    0
                  )
                  .toFixed(2)
              : 0,

          reqPrice: reservation?.noShowRefundPayments.price,
        };
      }
      return {};
    },
    noShowAudits() {
      const audits = _.find(this.reservationData.audits, {
        type: "NOSHOW_REFUND_PAYMENT_SUCCESS",
      });
      return {
        personInfo: audits?.author?.fullname,
        departmanRole: audits?.departmanRole,
      };
    },
    earlyReturnAudits() {
      if (this.reservationData?.statusEarlyReturn !== null) {
        let type;
        switch (this.reservationData.statusEarlyReturn) {
          case "pending":
            type = "STATUS_EARLY_RETURN_ADD_INFO";
            break;
          case "approved":
            type = "STATUS_EARLY_RETURN_SUCCESS";
            break;
          case "rejected":
            type = "STATUS_EARLY_RETURN_REJECTED";
            break;
        }

        if (type) {
          const audits = _.find(this.reservationData.audits, { type });
          return {
            personInfo: audits?.author?.fullname,
            departmanRole: audits?.departmanRole,
            date: audits?.date,
          };
        }
      }
      return {};
    },

    refundPayCardInfo() {
      const reservation = this.reservationData;
      if (
        reservation?.refundPayment?.status === true &&
        ["REFUND_PAYMENT"].includes(reservation?.refund?.place)
      ) {
        const audits = _.find(this.reservationData.audits, {
          type: "REFUND_PAYMENT_SUCCESS",
        });
        return {
          CardShow: true,
          title: "İADE ÖDEMESİ BİLGİLERİ",
          type: "İADE ÖDEMESİ",
          person: audits?.place,
          personInfo: audits?.author?.fullname,
          departmanRole: audits?.departmanRole,
          date: audits?.date,
          message: reservation?.refund?.message,
          auditsMessage: audits?.description,
          paymentType: reservation?.paymentType,
          refundPrice: reservation?.payments
            .reduce(
              (total, item) =>
                item.paymentType === "refund"
                  ? total + item.totalAmount
                  : total,
              0
            )
            .toFixed(2),
          reqPrice: reservation?.refundPayment.price,
        };
      }
      return {};
    },

    resultInstallmentMath() {
      const totalAmount = this.reservationData.payments.find(
        (item) => item.paymentType == "payment"
      )?.totalAmount;
      return totalAmount;
    },
    installmentCount() {
      return (
        this.reservationData.bankTransactions.find(
          (item) => item.type == "paymentSuccess"
        )?.data?.InstallmentCount || ""
      );
    },
    isPartner() {
      return this.$store.state.GetServerData?.role == "PARTNER";
    },
    isPay() {
      return this.reservationData.payments.find(
        (item) => item.paymentType == "payment"
      );
    },
    birthdayMoment() {
      if (this.reservationData.driverInfo.birthday) {
        return moment(this.reservationData.driverInfo.birthday).format(
          "DD.MM.YYYY"
        );
      }
    },
    yearMoment() {
      if (this.reservationData.driverInfo.birthday) {
        const dogumYil = new Date(
          this.reservationData.driverInfo.birthday ||
            this.reservationData.driverInfo.birthDate
        ).getFullYear();

        var simdikiYil = moment().format("YYYY");
        var sonuc = Number(simdikiYil) - Number(dogumYil);

        if (_.isNaN(sonuc)) {
          dogumYil = this.reservationData.driverInfo.birthDate.substring(0, 4);
          sonuc = Number(simdikiYil) - Number(dogumYil);
        }

        return sonuc;
      }
    },
    oldPrice() {
      var val;
      if (_.isObject(this.reservationData?.prices?.totalPrice)) {
        val = false;
      } else {
        val = true;
      }
      return val;
    },
    invoicesStatus() {
      var val;
      if (typeof this.reservationData.invoices === "undefined") {
        val = false;
      } else {
        val = true;
      }
      return val;
    },
    phoneFormat() {
      return this.reservationData.driverInfo.phone;
    },
    odenenTutuar: function () {
      var deger = 0;
      if (this.reservationData.bankTransactions.length > 0) {
        deger = parseFloat(this.bankTransactions.TotalAmount);
      } else {
        deger = parseFloat(this.reservationData.prices.payNow[this.currency]);
      }

      return deger;
    },
    odemeDurumu: function () {
      var deger;
      if (this.bankTransactions.InstallmentCount == 1) {
        deger = "Peşin Ödeme";
      } else {
        deger = this.bankTransactions.InstallmentCount + " Taksit";
      }
      return deger;
    },
    extrasSum: function () {
      var deger = _.sumBy(this.reservationData.selectedExtras, (o) => {
        var val;
        if (_.isObject(o?.totalPrice)) {
          val = o?.totalPrice[this.currency];
        } else {
          val = o.totalPrice;
        }

        return val;
      });

      return deger;
    },
    extrasSumCurrency: function () {
      var deger = _.sumBy(this.reservationData.selectedExtras, (o) => {
        var val;
        if (_.isObject(o?.totalPrice)) {
          val = o?.totalPrice[this.currency];
        } else {
          val = o.totalPrice;
        }

        return val;
      });

      return null;
    },
    currency: function () {
      return this.reservationData.currency.code;
    },
  },
  watch: {
    earlyReturnDate: function (value) {
      this.earlyReturnCalcDays = moment(
        moment(this.reservationData.carDeliveryReturn?.return?.datetime).format(
          "YYYY-MM-DD"
        )
      ).diff(moment(this.earlyReturnDate).format("YYYY-MM-DD"), "days");
      this.calculateEarlyRefundPrice();
    },

    "$i18n.locale": function (value) {
      window.location.reload();
    },

    invoiceUpdate: function (value) {
      if (value) {
        this.invoiceUpdate = true;
      } else {
        this.invoiceUpdate = false;
      }
    },
    driverInfoUpdate: function (value) {
      if (value) {
        this.driverInfoUpdate = true;
      } else {
        this.driverInfoUpdate = false;
      }
    },
  },

  methods: {
    getSearchQueryApiLog() {
      this.viewApiLogs = true;
      this.detailApiLogs = [];
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "admin/reservation/api-log/" +
            this.reservationCode +
            "?type=searchQuery"
        )
        .then((response) => {
          this.detailApiLogs = response.data.detail;
        });
    },

    getReservationApiLog() {
      this.viewApiLogs = true;
      this.detailApiLogs = [];
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "admin/reservation/api-log/" +
            this.reservationCode
        )
        .then((response) => {
          this.detailApiLogs = response.data.detail;
        });
    },
    addResCancelReflectionModal() {
      this.resCancelReflectionModal = true;
      this.resCancelReflectionForm.audits.date = this.reservationData.updatedAt;
      axios
        .get(process.env.VUE_APP_API_URL + "admin/admins", {
          params: {
            userType: "admin",
            departmanRole: ["CallCenter", "CustomerExperience"],
          },
        })
        .then((response) => {
          this.cancellationAdminInfoData = response.data.adminList.map(
            (admin) => {
              return {
                _id: admin._id,
                fullname: admin.name + " " + admin.lastname,
                departmanRole: admin.departmanRole,
              };
            }
          );
        });
    },
    addResCancelInfoUser() {
      this.resCancelReflectionForm.refund.place = "CUSTOMER_NORMAL";
      this.resCancelReflectionForm.audits.departmanRole = "";
      this.resCancelReflectionForm.audits.author._id =
        this.reservationData.userID;
      this.resCancelReflectionForm.audits.author.fullname =
        this.reservationData.driverInfo.name +
        " " +
        this.reservationData.driverInfo.lastname;
      this.resCancelReflectionForm.audits.place = "CUSTOMER";
      // if (this.isPaymentsReflection) {
      //   this.resCancelReflectionForm.payments = {
      //     posModule: this.reservationData.payments[0].posModule,
      //     totalAmount: -this.reservationData.payments[0].totalAmount,
      //     orderId: this.reservationData.payments[0].orderId,
      //     created: this.reservationData.updatedAt,
      //     paymentType: "refund",
      //     channel: "pos",
      //     creditCardCountry: this.reservationData.payments[0].creditCardCountry,
      //   };
      // }
    },
    async addResCancelReflection() {
      try {
        if (this.isUserRefundReflection) {
          this.addResCancelInfoUser();
        }
        let body = {
          refund: this.resCancelReflectionForm.refund,
          audits: this.resCancelReflectionForm.audits,
        };
        if (
          this.resCancelReflectionForm.refund.message &&
          this.resCancelReflectionForm.audits.author.fullname
        ) {
          await axios.put(
            process.env.VUE_APP_API_URL +
              "admin/reservation-cancel-reflection/" +
              this.reservationData._id,
            body,
            {
              withCredentials: true,
            }
          );
          this.resCancelReflectionModal = false;
          window.location.reload();
          Vue.swal({
            title: "Başarılı!",
            icon: "success",
            html: "İptal Bilgileri yansıtıldı",
          });
        } else {
          Vue.swal({
            icon: "error",
            title: "Hata",
            html: "Lütfen Tüm Alanları Doldurun",
            confirmButtonText: this.$t("okey"),
          });
        }
      } catch (error) {
        console.log(error);
        Vue.swal({
          icon: "error",
          title: "Hata",
          html: error?.response?.data?.errors[0]?.message,
          confirmButtonText: this.$t("okey"),
        });
      }
    },
    addResCancelInfo() {
      this.resCancelReflectionForm.audits.departmanRole =
        this.cancellationAdminInfo.departmanRole;
      this.resCancelReflectionForm.audits.author._id =
        this.cancellationAdminInfo._id;
      this.resCancelReflectionForm.audits.author.fullname =
        this.cancellationAdminInfo.fullname;

      // if (this.isPaymentsReflection) {
      //   this.resCancelReflectionForm.payments = {
      //     posModule: this.reservationData.payments[0].posModule,
      //     totalAmount: -this.reservationData.payments[0].totalAmount,
      //     orderId: this.reservationData.payments[0].orderId,
      //     created: this.reservationData.updatedAt,
      //     paymentType: "refund",
      //     channel: "pos",
      //     creditCardCountry: this.reservationData.payments[0].creditCardCountry,
      //   };
      // }
    },

    calculateEarlyRefundPrice() {
      let newDatas = {
        days:
          this.reservationData.searchPrice.totalDays - this.earlyReturnCalcDays,
        daysRefunded: this.earlyReturnCalcDays,
        vendorRefundPrice: Number(this.earlyReturnPaymentAmount.toFixed(2)),
        vendorPlusComission:
          1 +
          (this.reservationData.searchPrice.totalDays < 30
            ? this.reservationData.searchPrice.vendor.plusTotalPrice
            : this.reservationData.searchPrice.vendor.plusMonthTotalPrice) /
            100,
        vendorDailyPrice: null,
        vendorTotalPrice: null,
        newRetailPrice: null,
        newDailyRetailPrice: null,
        calculatedRefundPrice: null,
      };

      newDatas.vendorDailyPrice = Number(
        (
          (this.reservationData.searchPrice.pricing.vendorTotalPrice.TRY -
            newDatas.vendorRefundPrice) /
          newDatas.days
        ).toFixed(2)
      );
      newDatas.vendorTotalPrice = Number(
        (
          this.reservationData.searchPrice.pricing.vendorTotalPrice.TRY -
          newDatas.vendorRefundPrice
        ).toFixed(2)
      );
      newDatas.newRetailPrice = this.isManuelEarlyReturnPayment
        ? Number(
            (
              this.reservationData.searchPrice.pricing.totalPrice.TRY -
              newDatas.vendorRefundPrice
            ).toFixed(2)
          )
        : Number(
            (newDatas.vendorTotalPrice * newDatas.vendorPlusComission).toFixed(
              2
            )
          );
      newDatas.newDailyRetailPrice = Number(
        (newDatas.newRetailPrice / newDatas.days).toFixed(2)
      );
      newDatas.calculatedRefundPrice = Number(
        (
          this.reservationData.prices.totalRentalPrice.TRY -
          newDatas.newRetailPrice
        ).toFixed(2)
      );

      newDatas.calculatedRefundPrice =
        newDatas.calculatedRefundPrice < 0 ? 0 : newDatas.calculatedRefundPrice;
      this.calculatedRefundPrice = newDatas.calculatedRefundPrice;
      this.earlyReturnNewDatas = newDatas;
    },

    async noShowRefundPayment() {
      var reservationCode = this.reservationCode;
      var typePayment = this.typeOfNoShowRefundPayment || "PARTIAL";
      var partialPrice =
        typePayment == "TOTAL"
          ? this.reservationData?.prices?.payNow?.TRY
          : this.partialNoShowRefundPayment;

      Vue.swal
        .fire({
          title: "Uyarı ?",
          text: `${this.reservationCode}  numaralı rezervasyona NoShow ücret iadesi yapmak istediğinize emin misiniz?`,
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          cancelButtonText: this.$t("leave"),
          confirmButtonText: this.$t("i_approve"),
          preConfirm: async () => {
            try {
              await axios.post(
                process.env.VUE_APP_API_URL +
                  "admin/reservation/noshow-payment",
                { partialPrice, typePayment, reservationCode },
                { withCredentialswithCredentials: true }
              );

              this.$store.dispatch({
                type: "successSwal",
                text: "İade ödemesi başarıyla yapıldı",
                refresh: true,
              });
            } catch (error) {
              Vue.swal({
                icon: "error",
                title: this.$t("payment"),
                html: error?.response?.data?.errors[0]?.message,
                confirmButtonText: this.$t("okey"),
              });
            }
          },
          allowOutsideClick: () => !Vue.swal.isLoading(),
        })
        .then((result) => {
          this.alert("Başarılı", "success");
          if (!result.isConfirmed) {
            (this.noShowRefundPaymentModel = false),
              ((this.partialNoShowRefundPayment = 0), (partialPrice = 0));
          }
        });
    },
    async refundPayment() {
      var reservationCode = this.reservationCode;
      var typePayment = this.typeOfRefundPayment;
      var partialPrice =
        typePayment == "TOTAL"
          ? this.reservationData?.prices?.payNow?.TRY
          : this.partialRefundPayment;

      Vue.swal
        .fire({
          title: "Uyarı ?",
          text: `${this.reservationCode}  numaralı rezervasyona ücret iadesi yapmak istediğinize emin misiniz?`,
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          cancelButtonText: this.$t("leave"),
          confirmButtonText: this.$t("i_approve"),
          preConfirm: async () => {
            try {
              await axios.post(
                process.env.VUE_APP_API_URL +
                  "admin/reservation/refund-payment",
                { partialPrice, typePayment, reservationCode },
                { withCredentialswithCredentials: true }
              );

              this.$store.dispatch({
                type: "successSwal",
                text: "İade ödemesi başarıyla yapıldı",
                refresh: true,
              });
            } catch (error) {
              Vue.swal({
                icon: "error",
                title: this.$t("payment"),
                html: error?.response?.data?.errors[0]?.message,
                confirmButtonText: this.$t("okey"),
              });
            }
          },
          allowOutsideClick: () => !Vue.swal.isLoading(),
        })
        .then((result) => {
          if (!result.isConfirmed) {
            (this.refundPaymentModel = false),
              ((this.partialRefundPayment = 0), (partialPrice = 0));
          }
        });
    },
    async debitReservaitonDateChange(form) {
      try {
        if (form.newDate == null) {
          Vue.swal({
            icon: "error",
            title: this.$t("reservation"),
            html: "Lütfen tarih seçiniz",
            confirmButtonText: this.$t("okey"),
          });
          return;
        }

        await axios
          .post(
            process.env.VUE_APP_API_URL +
              "admin/reservation/debit-date-change/" +
              this.reservationData.reservationCode,
            {
              form,
            }
          )
          .then((res) => {
            if (res.data.result == "success") {
              Vue.swal({
                icon: "success",
                title: this.$t("reservation"),
                html: "Tarih değişikliği başarıyla yapıldı",
                confirmButtonText: this.$t("okey"),
              });
              this.reservationDateUpdateForm.newDate = null;
              this.reservationDateUpdateForm.newVendorPrice = 0;
              this.reservationDateUpdateForm.newRentalPrice = 0;
              this.reservationDateUpdateModal = false;
            }
          });
      } catch (error) {
        Vue.swal({
          icon: "error",
          title: this.$t("reservation"),
          html: error.response.data.errors[0].message,
          confirmButtonText: this.$t("okey"),
        });
      }
    },
    async addExtras(extra) {
      try {
        this.$store.state.isLoading = true;
        await axios
          .post(
            process.env.VUE_APP_API_URL +
              "admin/reservation/add-extras/" +
              this.reservationData.reservationCode,
            {
              extra,
            }
          )
          .then((res) => {
            if (res.data.result == "success") {
              this.$store.state.isLoading = false;
              this.addExtrasModal = false;
              this.reservationGetDetail();
            }
          });
      } catch (error) {
        this.$store.state.isLoading = false;
        this.addExtrasModal = false;
        Vue.swal({
          icon: "error",
          title: this.$t("reservation"),
          html: error.response.data.errors[0].message,
          confirmButtonText: this.$t("okey"),
        });
      }
    },
    async setApproved() {
      try {
        await axios
          .post(
            process.env.VUE_APP_API_URL +
              "admin/reservation/set-approved/" +
              this.reservationData.reservationCode
          )
          .then((res) => {
            if (res.data.result == "success") {
              this.approvedExtrasForVendorModal = false;
              this.reservationGetDetail();
            }
          });
      } catch (error) {
        console.log(error);
      }
    },

    async deleteExtras(extra) {
      try {
        this.$store.state.isLoading = true;
        await axios
          .post(
            process.env.VUE_APP_API_URL +
              "admin/reservation/delete-extras/" +
              this.reservationData.reservationCode,
            {
              extra,
            }
          )
          .then((res) => {
            this.$store.state.isLoading = false;
            this.extraUpdateModal = false;
            if (res.data.result == "success") {
              this.reservationGetDetail();
            }
          });
      } catch (error) {
        this.$store.state.isLoading = false;
        this.extraUpdateModal = false;
        console.log(error);
      }
    },
    async updateExtras(extra) {
      try {
        this.$store.state.isLoading = true;
        let res = await axios.post(
          process.env.VUE_APP_API_URL +
            "admin/reservation/update-extras/" +
            this.reservationData.reservationCode,
          {
            extra,
          }
        );
        this.extraUpdateModal = false;
        this.$store.state.isLoading = false;
        if (res.data.result == "success") {
          this.reservationGetDetail();
        }
      } catch (error) {
        this.$store.state.isLoading = false;
        this.extraUpdateModal = false;
        console.log(error);
      }
    },

    invoiceType(data) {
      if (data == "individual") {
        return this.$t("individual");
      } else if (data == "individualCompany") {
        return this.$t("individualCompany");
      } else if (data == "company") {
        return this.$t("company");
      } else if (data == "abroad") {
        return this.$t("abroadCompany");
      }
    },
    redirectComment() {
      let routeData = this.$router.resolve({
        name: this.$t("comments"),

        query: {
          reservationCode: this.reservationData.reservationCode,
        },
      });
      window.open(routeData.href, "_blank");
    },
    setPaymentTypes() {
      const types = this.$store.state.GetServerData.typesOfPaymentAvailable;
      const userType = this.$store.state.GetServerData.userType;
      if (userType == "admin") {
        this.reservationSetting.typesOfPaymentAvailableOptions =
          defaultPaymentTypes;
      } else {
        this.reservationSetting.typesOfPaymentAvailableOptions = types.map(
          (type) => {
            return {
              label: defaultPaymentTypes.find(
                (defaultPayType) => defaultPayType.value == type
              ).label,
              value: type,
            };
          }
        );
      }
    },
    async resDocumentUpdate(type) {
      try {
        // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // const phoneRegex = /^\+\d+$/;

        const oldDriverInfo = this.reservationData.driverInfo;
        const oldInvoices = this.reservationData.invoices;
        const oldERPInfo = this.reservationData.ERP || null;
        let body;
        if (type === "driverInfo") {
          const isDriverInfoValid = await this.$refs.driverInfoForm.validate();
          if (!isDriverInfoValid) {
            return;
          }
          this.reservationData.driverInfo = this.driverInfoUpdateForm;
          // Kullanıcın kimlik numarasına göre pasaportId veya tcId alanları güncellenir
          if (
            this.driverInfoUpdateForm.identity.certificateType === "passport"
          ) {
            this.reservationData.driverInfo.passportId =
              this.driverInfoUpdateForm.identity.value;
            this.reservationData.driverInfo.tcId = "";
          } else if (
            this.driverInfoUpdateForm.identity.certificateType === "tc"
          ) {
            this.reservationData.driverInfo.tcId =
              this.driverInfoUpdateForm.identity.value;

            this.reservationData.driverInfo.passportId = "";
          }
          body = {
            _id: this.reservationData._id,
            driverInfo: this.reservationData.driverInfo,
            oldDriverInfo,
            oldDriverInfoIdentity: this.oldIdentityValue,
            type: "driverInfo",
          };
        } else if (type === "invoices") {
          // Değiştirilen fatura tipi bilgileri sıfırlanıyor

          const invoiceType = this.invoiceUpdateForm.invoiceType;
          const isInvoicesValid = await this.$refs[invoiceType].validate();
          if (!isInvoicesValid) {
            return;
          }

          if (this.invoiceUpdateForm.invoiceType != oldInvoices.invoiceType) {
            this.invoiceUpdateForm[oldInvoices.invoiceType] =
              this.invoiceResetForm[oldInvoices.invoiceType];
            oldInvoices[this.invoiceUpdateForm.invoiceType] =
              this.invoiceResetForm[this.invoiceUpdateForm.invoiceType];
          }
          this.reservationData.invoices = this.invoiceUpdateForm;

          if (this.reservationData.ERP) {
            this.reservationData.ERP = this.invoiceERPForm;

            body = {
              _id: this.reservationData._id,
              invoices: this.reservationData.invoices,
              ERP: this.reservationData.ERP || null,
              oldInvoices,
              oldERPInfo,
              type: "invoices",
            };
          } else {
            body = {
              _id: this.reservationData._id,
              invoices: this.reservationData.invoices,
              oldInvoices,
              type: "invoices",
            };
          }
        }
        // if (err) {
        //   this.reservationData.driverInfo = oldDriverInfo;
        //   this.reservationData.invoices = oldInvoices;
        //   return err;
        // }
        await axios.put(
          process.env.VUE_APP_API_URL + "admin/reservation",
          body,
          {
            withCredentials: true,
          }
        );
        this.invoiceUpdate = false;
        this.driverInfoUpdate = false;
        Vue.swal({
          title: "Başarılı!",
          icon: "success",
          html: "Sürücü Bilgiler Güncellendi",
        });
        window.location.reload();
      } catch (error) {
        Vue.swal({
          icon: "error",
          title: "Hata!",
          html: "Sürücü Bilgileri Güncellenemedi",
        });
      }
    },
    async reservationUpdate() {
      const changeVariableNames = ["paymentType", "spendingObjection"];
      let body = {
        _id: this.reservationData._id,
      };
      Object.keys(this.reservationData).map((key) => {
        if (changeVariableNames.includes(key)) {
          body[key] = this.reservationData[key];
        }
      });
      this.reservationSetting.modalLoading = true;
      try {
        await axios.put(
          process.env.VUE_APP_API_URL + "admin/reservation",
          body,
          {
            withCredentials: true,
          }
        );
      } catch (error) {
      } finally {
        this.reservationSetting.modalShow = false;
        this.reservationSetting.modalLoading = false;
      }
    },
    earlyReturnRequestButton() {
      if (this.reservationData.consensus.status == "completed") return false;
      if (
        this.reservationData.statusEarlyReturn == "pending" ||
        this.reservationData.statusEarlyReturn == "approved"
      )
        return false;
      if (this.reservationData.isEarlyReturn) return false;
      if (
        this.reservationData.status == "active" ||
        this.reservationData.status == "completed"
      )
        return true;
      else return false;
    },
    cacelBtnClick(type) {
      /**
       * @TYPES
       * one => tek tek
       * all => hepsi
       */
      this.cancelBtnType = type;
      this.reservastionCancelModal = true;
    },
    async makeNoneSecurePayment() {
      this.createPaymentBtnLoading = true;
      const expiry = this.paymentNoneSecureForm.expiry.split("/");
      let body = {
        _id: this.reservationData._id,
        InstallmentCount: 1,
        ExpiryDateYear: expiry[1],
        ExpiryDateMonth: expiry[0],
        Cvv: this.paymentNoneSecureForm.cvv,
        CardNumber: this.paymentNoneSecureForm.cardNumber.split(" ").join(""),
        CardHolderName: this.paymentNoneSecureForm.cardOwner,
        paymentRole: "adminPanel",
        paymentType: "NoneSecure",
      };
      try {
        const result = await axios.post(
          process.env.VUE_APP_API_URL + "payment/make-none-secure",
          body,
          {
            withCredentialswithCredentials: true,
          }
        );
        window.location.href = result.data.url;
      } catch (error) {
        Vue.swal
          .fire({
            icon: "error",
            showCloseButton: true,
            text:
              error.response.data.message ||
              error.response.data.errors[0].message,
          })
          .then((result) => {
            return;
          });
      } finally {
        this.createPaymentBtnLoading = false;
      }
    },
    async getReasonsOfCancel() {
      try {
        let result = await axios.get(
          `${process.env.VUE_APP_API_URL}admin/singular?slug=CANCEL_QUESTIONS`,

          {
            withCredentials: true,
          }
        );

        var items = result.data.data.items;
        _.forEach(items, (item) => {
          if (item.status === "active") {
            this.reasonsOfCancel.push({
              question:
                this.$store.state.locale == "tr" ? item.name : item.name_en,
              vendorRulesAccepted: item.vendorRulesAccepted,
            });
          }
        });
      } catch (e) {
        this.statusComment = true;
      }
    },
    async getReasonsOfEarlyReturn() {
      try {
        let result = await axios.get(
          `${process.env.VUE_APP_API_URL}admin/singular?slug=EARLY_RETURN_QUESTIONS`,

          {
            withCredentials: true,
          }
        );

        var items = result.data.data.items;
        _.forEach(items, (item) => {
          if (item.status === "active") {
            this.reasonsOfEarlyReturn.push({
              question: item.name,
            });
          }
        });
      } catch (e) {
        this.statusComment = true;
      }
    },

    async surveySend(code) {
      try {
        await axios.post(
          process.env.VUE_APP_API_URL + "admin/comment/" + code,

          {
            withCredentials: true,
          }
        );

        this.$store.dispatch({
          type: "successSwal",
          text: "Anket başarılı bir şekilde telefon ve e-posta adresine gönderildi",
          refresh: false,
        });
      } catch (error) {}
    },

    confirmEarlyReturn() {
      Vue.swal
        .fire({
          icon: "question",
          title: this.$t("early_return_confirmation"),

          showCancelButton: true,
          cancelButtonText: this.$t("leave"),
          confirmButtonText: this.$t("i_approve"),
          showCloseButton: true,
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            try {
              await axios.post(
                process.env.VUE_APP_API_URL +
                  "admin/reservation/early-return/success/" +
                  this.reservationData.reservationCode,
                {
                  withCredentials: true,
                }
              );

              this.$store.dispatch({
                type: "successSwal",
                text: this.$t("early_return_confirmed"),
                refresh: true,
              });
            } catch (error) {
              Vue.swal({
                icon: "error",
                title: this.$t("reservation"),
                html: error.response.data.errors[0].message,
                confirmButtonText: this.$t("okey"),
              });
            }
          },

          allowOutsideClick: () => !Vue.swal.isLoading(),
        })
        .then((result) => {
          if (result.isConfirmed) {
          }
        });
    },

    rejectEarlyReturn() {
      Vue.swal
        .fire({
          icon: "question",
          title: this.$t("early_return_rejection"),
          input: "textarea",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          cancelButtonText: this.$t("leave"),
          confirmButtonText: this.$t("i_approve"),
          showCloseButton: true,
          showLoaderOnConfirm: true,
          preConfirm: async (objection) => {
            try {
              await axios.post(
                process.env.VUE_APP_API_URL +
                  "admin/reservation/early-return/reject/" +
                  this.reservationData.reservationCode,
                {
                  objection,
                },
                {
                  withCredentials: true,
                }
              );

              this.$store.dispatch({
                type: "successSwal",
                text: this.$t("early_return_denied"),
                refresh: true,
              });
            } catch (error) {
              Vue.swal({
                icon: "error",
                title: this.$t("reservation"),
                html: error.response.data.errors[0].message,
                confirmButtonText: this.$t("okey"),
              });
            }
          },

          allowOutsideClick: () => !Vue.swal.isLoading(),
        })
        .then((result) => {
          if (result.isConfirmed) {
          }
        });
    },

    async earlyReturnRegistration() {
      this.formEarlyReturnRegistrationModal = false;
      var date = this.earlyReturnDate;
      var price = this.earlyReturnPaymentAmount;
      var calculatedRefundPrice = this.calculatedRefundPrice;
      var message = this.reasonEarlyReturn;

      try {
        await axios.post(
          process.env.VUE_APP_API_URL +
            "admin/reservation/early-return/pending/" +
            this.reservationData.reservationCode,
          {
            price,
            date,
            calculatedRefundPrice,
            message,
            earlyReturnNewDatas: this.earlyReturnNewDatas,
            name: this.reservationData.driverInfo.name,
          },
          {
            withCredentials: true,
          }
        );

        this.$store.dispatch({
          type: "successSwal",
          text: this.$t("early_return_register_info"),
          refresh: true,
        });
      } catch (error) {
        Vue.swal({
          icon: "error",
          title: this.$t("reservation"),
          html: error.response.data.errors[0].message,
          confirmButtonText: this.$t("okey"),
        });
      }
    },

    async earlyReturnRequest() {
      this.earlyReturnModal = false;

      var message = this.reasonEarlyReturn;

      try {
        await axios.post(
          process.env.VUE_APP_API_URL +
            "admin/reservation/early-return/pending/" +
            this.reservationData.reservationCode,
          {
            message,
          },
          {
            withCredentials: true,
          }
        );

        this.$store.dispatch({
          type: "successSwal",
          text: this.$t("early_return_register_info"),
          refresh: true,
        });
      } catch (error) {
        Vue.swal({
          icon: "error",
          title: this.$t("reservation"),
          html: error.response.data.errors[0].message,
          confirmButtonText: this.$t("okey"),
        });
      }
    },

    getLocationList() {
      var _this = this;

      axios
        .get(process.env.VUE_APP_API_URL + "admin/location?status=true")
        .then((response) => {
          response.data.locationList.forEach((e) => {
            _this.locationList.push({
              group: e.city,
              name: e.locationName,
              slug: e.slug,
            });

            _this.locationListSelectOptions.push({
              label: e.city + " - " + e.locationName,
              value: e.slug,
            });
          });
        });
    },

    async reservationGetDetail() {
      var _this = this;
      this.$Progress.start();

      axios
        .get(
          process.env.VUE_APP_API_URL +
            "admin/reservation/" +
            this.reservationCode,
          {
            withCredentials: true,
            headers: {
              lang: _this.$store.state.locale || "tr",
            },
          }
        )
        .then((response) => {
          this.$Progress.finish();
          let data = response.data?.reservations[0];
          // Rezervasyon tarihi sayfada gösterilmek için format işlemi yapılıyor
          this.reservationDateTime = moment(data.reservationDateTime).format(
            "DD.MM.YYYY HH:mm"
          );

          data.audits
            .filter((element) => element.type == "STATUS_CANCELLED")
            .map((element) => {
              this.reservationCancellationDate = element.date;
            });
          this.reservationData = data;
          if (this.$store.state.locale === "en") {
            this.reservationData.carDeliveryReturn.delivery.name =
              this.reservationData?.carDeliveryReturn?.delivery?.nameEn?.locationNameEn;
            this.reservationData.carDeliveryReturn.return.name =
              this.reservationData?.carDeliveryReturn?.return?.nameEn?.locationNameEn;
          }

          this.addExrasItems = this.addExrasItemsModel;
          let filteredExtrasItems = this.reservationData.selectedExtras.filter(
            (a) => a.isManually == true && a.extraInvoiceStatus == "pending"
          );
          if (filteredExtrasItems.length > 0) {
            this.addExrasItems = this.addExrasItemsModel.filter(function (
              item
            ) {
              return !filteredExtrasItems.some(
                (filteredItem) => filteredItem.code === item.code
              );
            });
          }

          //   const filteredExtras = this.reservationData.selectedExtras.filter(
          //   (a) => a.totalPrice.TRY !== 0
          // );

          // filteredExtras.forEach((a) => {
          //   this.selectedItemForAddExtra.forEach((b) => {
          //     if (a.code === b.code) {
          //       b.price = a.totalPrice.TRY;
          //     }
          //   });
          // });

          this.driverInfoUpdateForm = this.reservationData.driverInfo;
          this.invoiceDetail = {
            invoiceType: this.invoiceType(data.invoices?.invoiceType),
            address:
              data.invoices[data.invoices.invoiceType]?.address +
              " " +
              data.invoices[data.invoices.invoiceType]?.province +
              " " +
              data.invoices[data.invoices.invoiceType]?.postCode,
            taxNumber: data.invoices[data.invoices.invoiceType]?.taxNumber,
            taxOffice: data.invoices[data.invoices.invoiceType]?.taxOffice,
            title:
              data.invoices[data.invoices.invoiceType]?.title ||
              data.invoices[data.invoices.invoiceType]?.firstname +
                " " +
                data.invoices[data.invoices.invoiceType]?.lastname,

            invoiceNumber: data.ERP?.invoiceNumber,
            invoiceTransferStatus: data.ERP?.invoiceTransferStatus,
            invoiceERPMessage: data.ERP?.invoiceERPMessage,
            invoiceDate: data.ERP?.invoiceDate,
            invoiceGUID: data.ERP?.invoiceGUID,
          };

          _.forEach(this.reservationData.bankTransactions, (result) => {
            if (result.type === "paymentSuccess") {
              this.bankTransactions = result.data;
            }
          });
          this.status = true;
          this.pageLoading = true;
          this.getAgentNotesTotal(this.reservationData._id);
        });
      if (this.$route.query.gtm == "true") {
        this.gtmNewReservation(this.reservationData);
      }
    },

    reservationPdfDownload(email, code) {
      window.open(
        process.env.VUE_APP_API_URL + "getReservationPdf/" + code,
        "_blank"
      );
    },

    async beginIVR(reservationCode) {
      try {
        // ? Environments.
        const { VUE_APP_API_URL } = process.env;

        // ? Cağrıyı IVR yönlendirir.
        await axios.post(
          `${VUE_APP_API_URL}admin/alotech/ivr/click-two-transfer/${reservationCode}`,
          undefined,
          {
            withCredentials: true,
          }
        );

        Vue.swal.fire({
          icon: "success",
          text: "Cağrınız ivr yönlendirildi.",
          showCancelButton: false,
        });
      } catch (error) {
        Vue.swal.fire({
          icon: "error",
          text: "İşlem Başarısız!",
          showCancelButton: false,
        });
      }

      // var params = {
      //   id,
      // };

      // try {
      //   await axios.post(
      //     process.env.VUE_APP_API_URL + "admin/reservation/success-resend-mail",
      //     params,
      //     {
      //       withCredentials: true,
      //     }
      //   );
      //   let timerInterval;
      //   Vue.swal
      //     .fire({
      //       icon: "success",
      //       allowOutsideClick: false,
      //       allowEscapeKey: false,
      //       allowEnterKey: false,
      //       text: "Onay E-postası başarılı bir şekilde gönderilmiştir",
      //       timer: 2000,
      //       timerProgressBar: true,
      //       showConfirmButton: false,
      //       willClose: () => {
      //         clearInterval(timerInterval);
      //       },
      //     })
      //     .then((result) => {
      //       if (result.dismiss === Vue.swal.DismissReason.timer) {
      //       }
      //     });
      // } catch (error) {
      //   Vue.swal.fire({
      //     icon: "error",
      //     text: error.response.data.message,
      //     showCancelButton: false,
      //   });

      // }
    },

    async reservationEmailSend(id) {
      //
      var params = {
        id,
      };

      try {
        await axios.post(
          process.env.VUE_APP_API_URL + "admin/reservation/success-resend-mail",
          params,
          {
            withCredentials: true,
          }
        );
        let timerInterval;
        Vue.swal
          .fire({
            icon: "success",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            text: this.$t("approve_email_desc"),
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
            willClose: () => {
              clearInterval(timerInterval);
            },
          })
          .then((result) => {
            if (result.dismiss === Vue.swal.DismissReason.timer) {
            }
          });
      } catch (error) {
        Vue.swal.fire({
          icon: "error",
          text: error.response.data.message,
          showCancelButton: false,
        });
      }
    },

    async reservationStatusUpdate(status) {
      try {
        var refundMessage = "";
        var vendorRulesAccepted = null;
        if (this.reasonCancel.otherMessage == 1) {
          refundMessage = this.otherReasonCancel;
          vendorRulesAccepted = true;
        } else {
          refundMessage = this.reasonCancel.message;
          vendorRulesAccepted = this.reasonCancel.vendorRulesAccepted;
        }
        if (!refundMessage.length) {
          Vue.swal({
            icon: "error",
            title: this.$t("reservation"),
            html: this.$t("warning.cancel_empty"),
            confirmButtonText: this.$t("okey"),
          });
          return;
        }
        this.reservastionCancelModal = false;

        Vue.swal.fire({
          icon: "warning",
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          text: this.$t("wait"),
        });
        var data = {};
        data.refundMessage = refundMessage;
        if (vendorRulesAccepted !== null) {
          data.vendorRulesAccepted = vendorRulesAccepted;
          data.isCancelwithRefundPrice = this.reasonCancel.refundPrice;
        }
        const reservationStatusUpdate = await axios.patch(
          `${process.env.VUE_APP_API_URL}admin/reservation/status/${this.reservationData._id}/${status}`,
          data,
          {
            withCredentials: true,
            headers: {
              lang: this.$store.state.locale || "tr",
            },
          }
        );
        if (
          reservationStatusUpdate.status == 200 &&
          status == "cancelled" &&
          !this.reservationData.isManuel &&
          this.reservationData.paymentType !== "debit"
        ) {
          this.$gtm.enable(true);
          this.$gtm.trackEvent({
            event: "vlEvent",
            label: "VL-ReservationCancel",
            vl_form: {
              detail: {
                purchase_id: this.reservationData.reservationCode,
                vl_userID: this.reservationData.userID,
                cancelMessage: refundMessage,
              },
            },
          });
        }
        this.$store.dispatch({
          type: "successSwal",
          text: this.$t("cancel_success_info"),
          refresh: true,
        });
      } catch (error) {
        Vue.swal({
          icon: "error",
          title: this.$t("reservation"),
          html: error.response.data.errors[0].message,
          confirmButtonText: this.$t("okey"),
        });
      }
    },
    async getAgentNotesTotal(id) {
      try {
        let agentNotes = await axios.get(
          process.env.VUE_APP_API_URL + "admin/note?place._id=" + id,
          {
            withCredentials: true,
          }
        );
        this.agentNotesTotal = agentNotes.data.length;
      } catch (error) {}
    },
    openInvoiceUpdateForm() {
      this.invoiceUpdate = true;
      this.invoiceUpdateForm = Object.assign({}, this.reservationData.invoices);
      this.invoiceERPForm = Object.assign({}, this.reservationData.ERP);
      this.invoiceERPForm.invoiceDate = this.$moment(
        this.invoiceERPForm.invoiceDate
      ).format("DD.MM.YYYY");
    },
    openDriverInfoUpdateForm() {
      this.driverInfoUpdate = true;
      this.driverInfoUpdateForm = Object.assign(
        {},
        this.reservationData.driverInfo
      );
      this.oldIdentityValue = Object.assign(
        {},
        this.reservationData.driverInfo.identity
      );
      this.driverInfoUpdateForm.birthday = this.$moment(
        this.driverInfoUpdateForm.birthday
      ).format("DD.MM.YYYY");
    },
    updateInvoiceType(selectedItem) {
      this.invoiceUpdateForm.invoiceType = selectedItem.value;
    },
    // handlePriceChange(item) {
    //   this.selectedItemForAddExtra = this.selectedItemForAddExtra.filter(
    //     (addedItem) => addedItem.code !== item.code
    //   );
    //   this.selectedItemForAddExtra.push(item);

    // },

    gtmNewReservation(reservation) {
      let purchaseProduct = [
        {
          id: reservation.searchPrice._id,
          qty: 1,
          price: reservation.searchPrice.pricing.totalPrice.TRY,
          brand: reservation.searchPrice.companySlug,
          item_name:
            reservation.searchPrice.car.brand +
            reservation.searchPrice.car.model,
          item_group_name: "Rented_Car",
        },
      ];
     // if (reservation.selectedExtras?.length > 0) {
       // reservation.selectedExtras.forEach((item) => {
         // purchaseProduct.push({
           // id: item.code,
           // qty: item.count, //adet
           // price: item.price.TRY, //fiyat
           // brand: item.vendor,
          //  item_name: item.name,
           // item_group_name: "Extra",
         // });
       // });
     // }
      this.$gtm.trackEvent({
        event: "vlEvent",
        label: "VL-PurchaseView",
        vl_ecommerce: {
          checkout: {
            purchase_id: reservation.reservationCode,
            vl_userID: reservation.userID,
            products: purchaseProduct,
          },
        },
      });
    },
  },
};
</script>
<style lang="scss">
.v-window {
  overflow: visible;
}

.down-btn {
  .v-btn__content {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
  }
}

.cancel-btn {
  button {
    background-color: red !important;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.approved {
  color: #2eb85c;
}

.pending,
.suspended {
  color: #f9b115;
}

.cancelled {
  color: #e55353;
}

.approved,
.pending,
.suspended,
.cancelled {
  font-weight: bold;
  margin-right: 5px;
}
</style>

<!-- ***=>TEMPLATE<=***

=>>REZERVASYON DETAY CARD
=>>SÜRÜCÜ BİLGİLERİ CARD 
=>>FATURA BİLGİLERİ CARD 
=>>ÖDEME BİLGİLERİ CARD 
=>>GENEL BİLGİLER CARD 
=>>ÖDEMELER CARD 
=>>POS HAREKETLERİ CARD 
=>>REZERVASYON ANKETİ CARD 
=>>ERKEN İADE BİLGİLERİ CARD 
=>>ERKEN İADE BİLGİLERİ 2  CARD 

=>>REZERVATİON CANCELLATION MODAL
=>>EARLY RETURN  MODAL
=>>EARLY  RETURN REGISTRATION FORM
=>>PAYMENT MODAL 
=>>REZERVATİON SETTİNGS   MODAL
=>>REFUND PAYMENT  MODAL -->
